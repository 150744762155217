.registerScreenOuterContainer {
    display: flex;
    justify-content: center;
    align-items: center;
}

.registerScreenLeftContainer,
.registerScreenRightContainer {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
}

.registerScreenRightContainer {
    flex-direction: column;
    height: 100vh;
    background-image: url("../../images/RegistrationScreenRightBackground.png");
    background-size: cover;
}

.userWithLaptop {
    height: 80vh;
    max-height: 100vh;
}

.registrationFormContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1.2rem;
}

.registrationFormContainer > label {
    display: flex;
    align-items: flex-start;
    align-self: stretch;
    flex: 1;
    font-weight: 400;
    font-size: 0.9rem;
    color: var(--white);
}

.skyFormLogo {
    width: 15%;
    margin-right: 2rem;
}

.userNameInputContainer,
.inputContainer,
.inputOuterContainer,
.contactInput,
.zipCodeInput {
    position: relative;
    display: flex;
    align-items: flex-start;
    gap: 0.5rem;
    align-self: stretch;
    flex: 1;
}

.inputContainer
 {
    position: relative;
    flex-direction: column;
    padding: .2rem;
    align-self: stretch;
    flex: 1;
}

.inputContainer > label,
.contactInput > label,
.zipCodeInput > label {
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    font-size: 0.8rem;
    padding: 0.3rem;
    color: var(--white);
}

label[data-required]::after {
    content: '*';
    color: var(--red);
    margin-left: 4px;
}

.inputField
{
    border-radius: 1rem;
    outline: none;
    border: none;
    padding: 0.3rem;
    align-self: stretch;
}

.emptyInputField
{
    border-radius: 1rem;
    outline: var(--red);
    border: 1px solid var(--red);
    padding: 0.3rem;
    align-self: stretch;
    box-shadow: 0 0 5px rgba(255, 0, 0, 0.8);
}

.contactNumberEmptyField {
    border-radius: 0rem 1rem 1rem 0rem;
    outline: var(--red);
    border: 1px solid var(--red);
    padding: 0.3rem;
    width: 7.5rem;
    box-shadow: 0 0 5px rgba(255, 0, 0, 0.8);
}

.signUpButton {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 0.3rem;
    margin: 2.5rem;
    border-radius: 1rem;
    border: none;
    background-color: var(--lavenderIndigo);
    color: var(--white);
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
    font-size: 0.8rem;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    box-shadow: var(--blurTransparentShadow);
}

.loaderImage {
    width: 25rem;
    height: 20rem;
}

.contactNumber {
    display: flex
}

.countryCode {
    border-radius: 1rem 0rem 0rem 1rem;
    width: 4.2rem;
    padding: 0.4rem;
    background-color: var(--lavenderIndigo);
    color: var(--white);
    align-self: stretch;
    border: var(--lavenderIndigo);
    outline: none;
    cursor: pointer;
    background-image: url("../../icons/down-arrow.png");
    background-repeat: no-repeat;
    background-position: 3.5rem;
}

.passwordInfoIcon {
    content: "";
    background-image: url("../../icons/info.png");
    background-repeat: no-repeat;
    background-size: contain;
    z-index: 2;
    width: 1rem;
    height: 1rem;
    position: absolute;
    top: 75%;
    right: 0.5rem;
    transform: translateY(-50%);
    cursor: pointer;
}

.tooltip {
    position: absolute;
    top: 100%;
    right: 0;
    z-index: 1;
    background-color: var(--lavenderBlue);
    color: var(--blueViolet);
    padding: 0.5rem;
    border-radius: 5px;
    word-spacing: 1px;
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
    font-size: 0.6rem;
}

.tooltip > span {
    color: #925CD6;
}

.tooltip::before {
    content: "";
    position: absolute;
    z-index: 1;
    top: -1rem;
    right: 0.4rem;
    border-width: 1em;
    border-style: solid;
    border-color: transparent transparent var(--lavenderBlue) transparent;
}

.countryCodeSearch {
    font-family: 'Public Sans', sans-serif;
    position: sticky;
    top: 0;
    z-index: 2;
    width: calc(100% - 1rem);
    padding: 6px;
    border: 2px solid var(--lavenderIndigo);
    box-shadow: 0px 3px 5px rgba(0, 0, 0, 1);
    outline: none;
    align-self: stretch;
}

.contactNumberInputField {
    border-radius: 0rem 1rem 1rem 0rem;
    outline: none;
    border: none;
    padding: 0.3rem;
    width: 7.5rem;
}

.countryCodeDropdownMenu {
    border: 1px solid var(--darkBlackShadow);
    max-height: 12.4rem;
    overflow: auto;
    width: 100%;
    background-color: var(--lavenderIndigo);
    position: absolute;
    z-index: 1;
    margin-left: 0.5rem;
    border-radius: 5px;
}

.countryCodeDropdownMenu::-webkit-scrollbar {
    width: 0.6rem;
    background-color: var(--lightPurple);
    border-radius: 5px;
}

.countryCodeDropdownMenu::-webkit-scrollbar-thumb {
    background-color: var(--white);
    border-radius: 5px;
}

.countryCodeDropdownOption:hover {
    background-color: var(--lightPurple);
}

.countryCodeDropdownOption {
    border: 0.5px solid black;
    padding: 0.5rem;
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
    font-size: 0.8rem;
    color: var(--white);
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.contactInput,
.zipCodeInput {
    position: relative;
    flex-direction: column;
    padding: .2rem;
}

.contactInput input[type="number"]::-webkit-inner-spin-button,
.contactInput input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.passwordInputOuterContainer {
    align-self: stretch;
    margin-bottom: 2vh;
}

.inputContainer > p,
.lastNameValidation,
.zipValidation,
.contactInput > p {
    position: absolute;
    top: 100%;
    left: 1rem;
    margin-top: 0.3rem;
    color: var(--yellow);
    text-align: left;
    font-family: 'Roboto', sans-serif;
    font-weight: 200;
    font-size: 0.8rem;
}

.InValidMailText {
    position: absolute;
    display: flex;
    margin-top: -13rem;
    margin-left: -12rem;
    color: var(--yellow);
    text-align: left;
    font-family: 'Roboto', sans-serif;
    font-weight: 200;
    font-size: 0.8rem;
}

.emailExistErrorText {
    position: absolute;
    display: flex;
    margin-top: -12.8rem;
    margin-left: -12rem;
    color: var(--red);
    text-align: left;
    font-family: 'Roboto', sans-serif;
    font-weight: 100;
    font-size: 0.8rem;
    text-shadow:
            0.04em 0.04em 0 var(--darkRed),
            0 -0.03em 0 var(--darkRed),
            -0.03em 0 0 var(--darkRed),
            0 0.03em 0 var(--darkRed);
}

.emailErrorIcon {
    position: absolute;
    right: 9.7rem;
    width: 1rem;
}

.passwordErrorContainer {
    position: absolute;
    display: flex;
    flex-direction: row;
    top: 85%;
    left: 1.8rem;
    margin-top: 0.2rem;
    font-size: 0.8rem;
    color:var(--yellow);
    font-family: Roboto, serif;
    font-weight: 400;
    text-align: center;
    width: 100%;
    align-content: center;
    justify-content: flex-start;
    height: 1.5rem;
    text-shadow:
            0.04em 0.04em 0 var(--darkRed),
            0 -0.03em 0 var(--darkRed),
            -0.03em 0 0 var(--darkRed),
            0 0.03em 0 var(--darkRed);
}

.passwordValidationText{
    position: absolute;
    left: -1rem;
}

.errorIcon {
    position: absolute;
    top: 0.7rem;
    right: 12.8rem;
    width: 1rem;
}

.successMessageContainer {
    background: var(--white);
    padding: 1rem;
    text-align: center;
    font-family: Roboto, serif;
    color: var(--blue);
    font-weight: 500;
}

.buttonContainer{
    margin-top: 0.5rem;
}

.successImage {
    max-width: 80px;
    margin-bottom: 1rem;
}

.nameContainer {
    margin-top: -1rem;
}

.dialogueMessage {
    font-size: .82rem;
    color: var(--gray);
}

.hideForm {
    display: none;
}

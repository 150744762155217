.deleteExtensionContainer {
    background: var(--white);
    padding: 0.5rem;
    border-radius: 10px;
    height: 100%;
    font-family: Roboto, sans-serif;
}

.confirmationMessage {
    color: var(--gray);
    font-size: .95em;
    padding-top: 1em;
    text-align: center;
    line-height: 1.5;
}

.confirmationContainer {
    display: flex;
    align-items: center;
    flex-direction: column;
}

.confirmationHeader {
    color: var(--blue);
    font-weight: bold;
}
.buttonContainer {
    margin-top: 1.5rem;
    display: flex;
    justify-content: center;
    gap: 1rem;
}
.businessRequirementFormContainer {
    background-color: var(--white);
    margin-top: 2.5rem;
}

.businessRequirementFormContainer > h2 {
    color: var(--indigo);
    text-align: center;
}

.businessRequirementContainer > p {
    margin: 0rem;
    text-align: left;
    color: var(--coldPurple);
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    padding: 0rem 1.5rem 0.3rem 0rem;
}

.proprietaryBusinessContainer,
.partnershipBasedBusinessContainer,
.privateBusinessContainer,
.resellerBusinessContainer {
    padding-bottom: 0.5rem;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.proprietaryBusinessText,
.partnershipBasedBusinessText,
.privateBusinessText,
.resellerBusinessText {
    color: var(--gray);
}

.proprietaryBusinessContainer > input[type="radio"],
.partnershipBasedBusinessContainer > input[type="radio"],
.privateBusinessContainer > input[type="radio"],
.resellerBusinessContainer > input[type="radio"],
.trafficTypeDialer > input[type="radio"],
.trafficTypeManual > input[type="radio"],
.trafficTypeRetail > input[type="radio"] {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    background-color: #fafafa;
    border-radius: 50%;
    border: 2px solid var(--coldPurple);
    width: 15px;
    height: 15px;
    display: inline-block;
    position: relative;
    cursor: pointer;
    margin-right: 0.5rem;
}

.proprietaryBusinessContainer > input[type="radio"]:checked,
.partnershipBasedBusinessContainer > input[type="radio"]:checked,
.privateBusinessContainer > input[type="radio"]:checked,
.resellerBusinessContainer > input[type="radio"]:checked,
.trafficTypeDialer > input[type="radio"]:checked,
.trafficTypeManual > input[type="radio"]:checked,
.trafficTypeRetail > input[type="radio"]:checked {
    box-shadow: 0 0 4px 3px var(--coldPurple);
    background-color: var(--coldPurple);
    border: 2px solid var(--white);
}

.proprietaryBusinessContainer > input[type="radio"]:checked:after,
.partnershipBasedBusinessContainer > input[type="radio"]:checked:after,
.privateBusinessContainer > input[type="radio"]:checked:after,
.resellerBusinessContainer > input[type="radio"]:checked:after,
.trafficTypeDialer > input[type="radio"]:checked:after,
.trafficTypeManual > input[type="radio"]:checked:after,
.trafficTypeRetail > input[type="radio"]:checked:after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    width: 9px;
    height: 9px;
    background-color: var(--coldPurple);
    transform: translate(-50%, -50%);
    border-radius: 50%;
}

.invalidBusinessType input[type="radio"] {
    border-color: red !important;
}

.invalidBusinessType input[type="radio"]:checked {
    background-color: red !important;
    box-shadow: 0 0 4px 3px red !important;
    border: 2px solid var(--white) !important;
}

.invalidBusinessType input[type="radio"]:checked:after {
    background-color: red !important;
}

.trafficTypeContainer,
.tradingNameContainer,
.businessRequirementContainer {
    margin-top: 0.5rem;
    padding-left: 3rem;
    padding-bottom: 0.3rem;
}

.trafficTypeContainer > label,
.tradingNameContainer > label {
    color: var(--coldPurple);
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    margin: 0.3rem 0.3rem 0.8rem;
}

.tradingNameContainer > input {
    width: 90%;
}

.trafficType {
    display: flex;
    padding-top: 0.5rem;
    gap: 3rem;
    margin-bottom: 0.5rem;
}

.trafficTypeDialer > span,
.trafficTypeManual > span,
.trafficTypeRetail > span {
    color: var(--gray);
}

.boldText {
    font-weight: bold;
}

.arrowRightIcon {
    color: var(--white);
}

.error {
    color: red;
    margin-bottom: 1.1rem;
    font-size: 0.8rem;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-left: 3.5rem;
}

.error > img {
    width: 1rem;
    height: 1rem;
    padding-right: 0.3rem;
}

@media only screen and (max-width: 480px) {
    .trafficTypeContainer,
    .tradingNameContainer,
    .businessRequirementContainer {
        padding-left: 1rem;
    }

    .businessRequirementContainer > p,
    .trafficTypeContainer > label,
    .tradingNameContainer > label {
        font-size: 0.8rem;
    }

    .proprietaryBusinessContainer,
    .partnershipBasedBusinessContainer,
    .privateBusinessContainer,
    .resellerBusinessContainer {
       align-items: flex-start;
    }
}
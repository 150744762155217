.virtualNumberContainer {
    height: 90%;
    border-radius: 20px;
    background: var(--white);
    box-shadow: var(--semiTransparentDarkShadow);
}

.rowHeader {
    display: flex;
    justify-content: center;
    padding-top: 0.2em;
}

.purchaseNumberBtn {
    display: flex;
    align-items: center;
    padding: 0.1em 0.5em;
}

.purchaseNumberBtn span {
    padding-left: 0.3em;
    font-size: 0.9vw;
}

.purchaseNumberBtn svg {
    font-size: 1vw;
}

.arrowDownIcon {
    transform: rotateZ(-90deg);
    font-size: .8rem !important;
    margin-left: .5rem;
    cursor: pointer;
}

.dropdownTableContainer {
    position: absolute;
    margin-top: 0;
    background: var(--white);
    padding: 1rem;
    z-index: 2;
    box-shadow: var(--transparentBlackShadow);
    border-radius: 10px;
}

.dropdownTableContainer th:first-of-type {
    border-right: var(--grayBorder);
}

.dropdownTableContainer th {
    border-bottom: var(--grayBorder);
    color: var(--blue);
}

.dropdownTableContainer tr td:first-of-type {
    border-right: var(--grayBorder);
}

.dropdownTableContainer th, .dropdownTableContainer td {
    padding: .1rem;
}

.upward {
    transform: rotateZ(90deg) translateX(5px);
}

.dot {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: var(--green);
    margin: auto;
}

.green {
    background: var(--green);
}

.red {
    background: var(--red);
}

.redirectBlueIcon {
    color: var(--blue);
    cursor: pointer;
}

.redirectBlueIcon:hover {
    transform: scale(1.2);
}

.redirectGreenIcon{
    color: var(--green);
    cursor: pointer;
}

.redirectGreenIcon:hover {
    transform: scale(1.2);
}

@media only screen and (max-width: 480px) {
    .purchaseNumberBtn svg {
        font-size: 0.8rem;
    }

    .purchaseNumberBtn span {
        font-size: 1.5dvw;
    }

    .purchaseNumberBtn {
        padding: 0.1em 0.2em;
    }
}

@media only screen and (min-width: 480px) and (max-width: 720px) {
    .purchaseNumberBtn svg {
        font-size: 0.8rem;
    }

    .purchaseNumberBtn span {
        font-size: 1.8dvw;
    }

    .purchaseNumberBtn {
        padding: 0.1em 0.2em;
    }
}
.addExtensionOuterContainer {
    background: var(--white);
    padding: 0.5rem;
    border-radius: 10px;
    height: 100%;
}

.textLabel {
    color: var(--blue);
    font-weight: bold;
    font-size: .8rem;
    margin: .5rem .2rem;
}

.extensionInputBox {
    border: 0;
    border-radius: 10px;
    margin-top: 0.3rem;
    color: var(--gray);
}

.extensionInputBox::placeholder {
    font-size: .8rem;
}

.addExtensionButtonContainer {
    margin-top: 1.5rem;
    display: flex;
    justify-content: center;
    gap: 1rem;
}
.combinedInputContainer {
    display: flex;
    width: 100%;
    border-radius: 10px;
    box-shadow: var(--transparentBlackShadow);
}
.roundControl {
    border: 0;
    border-radius: 10px;
    padding: 0.47rem;
    width: 93%;
    margin-top: 0.3rem;
}
.vosAccountInput{
    border: 0;
    padding: 0.47rem;
    background-color: transparent;
}

.vosAccountInput {
    color: var(--gray);
    pointer-events: none;
    width: 3rem;
}

.roundControl::placeholder {
    font-size: .8rem;
}

.roundControl::-webkit-inner-spin-button,
.roundControl::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.roundControl span {
    margin: 0;
}

.helperText{
    margin: .5rem .2rem;
    color: var(--gray);
    font-size: 0.8rem;
}

.errorMessageContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: .75rem;
    color: var(--red);
    font-weight: bold;
    margin-top: 0.5rem;
}

.errorIcon {
    width: 1rem;
    margin-right: 6px;
}

.dotLoaderContainer {
    padding-top: 2em;
}

.confirmationContainer {
    display: flex;
    align-items: center;
    flex-direction: column;
}

.confirmationContainer > svg {
    color: var(--darkGreen);
    width: 2em;
    height: 2em;
}

.confirmationHeader {
    color: var(--blue);
    font-weight: bold;
}
.confirmationText {
    color: var(--gray);
    font-size: 0.8em;
    padding-top: 1em;
    padding-bottom: 0.5em;
    text-align: center;
}
.confirmationContainer > button {
    padding-left: 1.5em;
    padding-right: 1.5em;
}

@media only screen and (max-width: 480px) {

    .addExtensionButtonContainer{
        margin-top: .5rem;
    }

}
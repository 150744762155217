.container {
    background: white;
    border-radius: 12px;
    padding: 2.5rem;
    box-shadow: 0 8px 12px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    gap: .5rem;
}

.content {
    display: flex;
    flex-direction: column;
    max-height: 20rem;
    overflow-y: auto;
    scrollbar-width: thin;
    scrollbar-color: var(--blue) var(--titenWhite);
    background-color: #f9f9f9;
    border-radius: 10px;
    padding: 0 1rem;
}

.content::-webkit-scrollbar {
    width: 0.5rem;
}

.content::-webkit-scrollbar-track {
    background-color: var(--titenWhite);
}

.content::-webkit-scrollbar-thumb {
    background-color: var(--blue);
    border-radius: 4px;
}

.itemTitle {
    margin-top: 0;
    color: var(--blue);
    font-size: 1.2rem;
    font-weight: 600;
    margin-bottom: 0.4rem;
}

.item:first-of-type {
    margin-top: 1rem;
}

.itemText {
    color: #666;
    font-size: 1rem;
    line-height: 1.4;
}

.divider {
    opacity: .5;
    border: none;
    border-top: 1px solid #8882ff;
}

.actions {
    display: flex;
    justify-content: center;
}
.checkboxContainer {
    display: flex;
    align-items: center;
    margin-top: 0.5rem;
}

.checkboxLabel {
    margin-left: 0.5rem;
    color: #666;
    font-size: 0.9rem;
    cursor: pointer;
}

.disabledCheckboxLabel{
    color: var(--lightGray);
}
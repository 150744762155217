.virtualNumberExtension{
    height: 90%;
    border-radius: 20px;
    background: var(--white);
    box-shadow: var(--semiTransparentDarkShadow);
}
.virtualNumberExtensionData{
    height: 99%;
}
.createExtensionBtn {
    display: flex;
    align-items: center;
    padding: 0.1em 0.5em;
}

.dot {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: var(--green);
    margin: auto;
}

.activeStatus {
    background: var(--green);
}

.inactiveStatus {
    background: var(--red);
}

.pendingStatus {
    background: var(--yellow);
}

.actionIcons {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 1rem;
    position: relative;
}
.icon {
    color: var(--blue);
    cursor: pointer;
    flex-shrink: 0;
}

.moreOptionsWrapper {
    position: relative;
}

.moreOptionsMenu {
    position: absolute;
    top: 100%;
    right: 0;
    background: var(--white);
    border-radius: 10px;
    box-shadow: var(--darkBlackShadow);
    min-width: 15em;
    z-index: 1000;
    max-height: 10rem;
    overflow-y: auto;
    scroll-behavior: smooth;
    transition: transform 0.2s ease;

}

.openUpward {
    top: auto;
    bottom: 100%;
}

.menuItem {
    display: flex;
    align-items: center;
    gap: 0.8rem;
    padding: 0.8rem 1rem;
    cursor: pointer;
    color: var(--blue);
    transition: background-color 0.2s;
}

.menuItem:hover {
    background-color: rgba(0, 0, 0, 0.04);
}
.menuItem span {
    white-space: nowrap;
}
.menuItem svg {
    font-size: 1.2rem;
    flex-shrink: 0;
}

.deleteItem {
    color: var(--red);
    border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.disabled {
    pointer-events: none;
    opacity: 0.5;
    cursor: not-allowed;
}


.moreOptionsMenu::-webkit-scrollbar-thumb {
    background: var(--gray);
    border-radius: 4px;
}

@media screen and (max-width: 768px) {
    .virtualNumberExtension {
        height: 100%;
        border-radius: 10px;
    }
    .virtualNumberExtensionData span {
        display: block;
        word-break: break-word;
        padding: 0.4rem;
        font-size: 0.9em;
    }

    .createExtensionBtn {
        padding: 0.2em 0.4em;
        font-size: 0.9em;
        gap: 0.5em;
    }

    .createExtensionBtn span {
        display: none;
    }

    .createExtensionBtn svg {
        width: 1.2rem;
        height: 1.2rem;
    }

    .actionIcons {
        gap: .3rem;
    }
}
@media screen and (max-width: 480px) {
    .virtualNumberExtensionData span {
        padding: 0.3rem;
        font-size: .8em;
    }
    .moreOptionsMenu {
        min-width: 8em;
        max-height: 8rem;
    }

    .menuItem {
        padding: 0.5rem 0.6rem;
        font-size: 0.8rem;
    }


}

.loaderContainer {
    width: 15rem;
    z-index: 9999;
}

.loader {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 20px;
}

.formLabel {
    font-size: 1rem;
    font-weight: 500;
    font-style: normal;
    color: var(--yellow);
    line-height: 17px;
}

.logoImage {
    margin-left: calc(50% - 2.5rem);
    margin-top: -4rem;
    margin-bottom: 2rem;
    width: 5rem;
    border-radius: 50%;
}

.logoImageLoading {
    border: .4rem solid var(--white);
    margin-left: calc(50% - 2.9rem);
}

.fieldContainer {
    margin-top: 0.5rem;
}

.text {
    font-size: 1.9rem;
    font-weight: 500;
    color: var(--yellow);
    text-align: center;
}

.formInput {
    width: 100%;
    height: 2.2rem;
    border-radius: 40px;
    border: none;
    margin-left: -.1rem;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
    color: var(--white);
    background-color: rgba(255, 255, 255, 0.2);
    margin-top: 10px;
    font-size: 1rem;
}

input {
    text-indent: 3%;
}

input::placeholder {
    font-size: 11px;
    letter-spacing: 0.8px;
    color: rgba(0, 0, 0, 0.5);
}

.inputError {
    border: 2px solid red;
    opacity: 0.8;
}

.formButtonContainer {
    margin-top: 1rem;
}

.errorMessageContainer {
    font-size: .7rem;
    color: var(--white);
    font-weight: 500;
    margin-top: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
}

.errorIcon {
    width: 1.2rem;
    margin-right: 6px;
    filter: drop-shadow(0 0 6px rgba(255, 0, 0, 0.7))
}

.forgotPasswordText {
    margin: 0.8rem 0;
    text-align: center;
    font-size: .8rem;
    color: var(--white);
    cursor: pointer;
    font-weight: bold;
    letter-spacing: 1px;
}

.button {
    width: 100%;
    border-radius: 20px;
    background: linear-gradient(to right, #FBBF13, #FB7005);
    padding: 0.5rem;
    border: none;
    color: var(--white);
    box-shadow: 2px 4px 10px rgba(0, 0, 0, 0.3);
}

/*@media (min-width: 720px) and (max-width: 1024px) {*/
/*    .errorMessageContainer {*/
/*        font-size: 0.6rem;*/
/*    }*/
/*}*/
.container {
    display: flex;
    height: 100%;
}

.mapContainer {
    flex: 1;
    display: grid;
    height: 90%;
    padding: 1rem;
}

.roundImageContainer, .earthImageContainer {
    grid-area: 1 / 1;

    display: flex;
    align-items: center;
    justify-content: center;
}

.earth, .round {
    width: 90%;
}

.ratesDataContainer {
    flex: 1;
    padding: 1.4%;
}

.gridContainer {
    border-radius: 6%;
    background: var(--white);
    box-shadow: var(--semiTransparentDarkShadow);
    height: 75%;
    overflow: hidden;
}

.rateSearchBar {
    display: flex;
    align-items: center;
    height: 2rem;
    width: 50%;
    margin-left: 50%;
    margin-bottom: 2rem;
    outline: none;
    border: 0;
    background: var(--white);
    border-radius: 16px;
    box-shadow: var(--slateBlackShadow);
}

.rateSearchInput {
    font-family: 'Public Sans', sans-serif;
    font-style: normal;
    color: var(--semiTransparentDarkBlue);
    border: 0;
    background: transparent;
    margin-left: 7px;
    margin-bottom: 3px;
    width: 94%;
}

.rate_search_input::placeholder {
    position: relative;
    left: -6px;
    font-size: 1rem;
}

.rateSearchButton {
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--blue);
    border-radius: 15px;
    height: 100%;
    font-size: .8rem;
    color: var(--white);
    cursor: default;
    flex: 2;
}

.searchIconContainer {
    flex: 1;
}

.searchInputContainer {
    flex: 8;
}

.rateSearchIcon {
    width: 1rem;
}

.rate_download_button_container {
    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--blue);
    width: 29%;
    height: 4%;
    margin-top: 10px;
    margin-left: 10px;
    border-radius: 20px;
    box-shadow: var(--darkShadeShadow);
}

.rate_download_button {
    font-size: 1rem;
    color: var(--white);
    font-weight: 400;
    font-family: 'Roboto', sans-serif;
    cursor: pointer;
}

@media screen and (max-width: 1024px) {
    .mapContainer {
        display: none;
    }

    .ratesDataContainer {
        padding-top: 0;
    }

    .rateSearchButton {
        padding: 0 0.5em;
    }

    .rateSearchBar {
        width: 30%;
        margin-left: 70%;
    }

    .rateSearchIcon {
        padding-right: 0.5rem;
    }
}

@media screen and (max-width: 480px) {
    .rateSearchBar {
        width: 50%;
        margin-left: 48%;
    }

    .rateSearchIcon {
        padding-right: 0.5rem;
        width: 0.7rem;
    }

    .rateSearchInput {
        margin-left: 4px;
        width: 95%;
    }

    .gridContainer {
        height: 80%;
    }
}
.parentContainer {
    height: 100%;
    display: flex;
}

.childContainer {
    height: 100%;
}

.menuBarContainer {
    flex: .5;
    display: flex;
    align-content: center;
    align-items: center;
}

.expand {
    flex: 1;
}

.menuBarContainerExpanded {
    flex: 1.5;
}

.contentContainer {
    flex: 4;
    margin-top: 5vh;
    padding-right: 1rem;
    max-height: 95%;
    overflow: auto;
}

.nestedChildContainer {
    display: flex;
    height: 87%;
    overflow: hidden;
    min-width: 0;
}

.outletContainer {
    flex: 3;
    padding: 0 1rem 1rem;
}

.shrink {
    flex: 2.5;
}

.profileCardContainer {
    display: flex;
    justify-content: center;
    min-width: 0;
    padding-top: .2rem;
    padding-right: .2rem;
}

.hide {
    display: none;
}

.bannerContainer {
    top: 0.2rem;
    position: fixed;
    z-index: -1;
    width: 100%;
    background-image: url("../../images/banner.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    text-align: center;
}

.bannerContainer p {
    font-size: 1vw;
    line-height: 1;
    padding-bottom: 0.8rem;
}

.bannerTextYellow {
    color: #eec03c;
}

.bannerTextWhite {
    color: white;
}

.kycPendingBannerContainer {
    position: fixed;
    width: 100%;
    text-align: center;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    height: 1.5rem;
    padding: 0.3rem 0rem;
    gap: 0.5rem;
}

.prodBanner {
    flex: 4;
    font-size: 80%;
    clip-path: polygon(0% 0%, 100% 0%, 90% 100%, 0% 100%);
    height: 1.9rem;
    display: flex;
    justify-content: center;
    align-items: center;
}

.kycBanner {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 0.5rem;
}

.kycPendingBannerContainer > img,
.loudspeaker {
    height: 4rem;
    width: 4rem;
    transform: scaleX(-1);
    padding-top: 1.5rem;
}

.kycPendingBannerContainer > p,
.kycBanner > p {
    color: var(--white);
    font-size: 0.8rem;
    line-height: 1;
}

.kycButton {
    cursor: pointer;
    height: 2rem;
}

.kycBannerDev {
    margin-right: 1rem;
    font-weight: bold;
    padding-left: 1.5rem;
    color: var(--darkRed)
}

.commentIcon {
    color: var(--white);
    cursor: pointer;
}

@media only screen and (max-width: 480px) {
    .outletContainer {
        position: relative;
        top: 3rem!important;
        height: auto;
        max-height: 85dvh;
        overflow-y: auto;
    }

    .outletContainer::-webkit-scrollbar{
        display: none;
    }

    .parentContainer {
        overflow: hidden;
        justify-content: flex-start;
    }

    .outletContainer {
        padding: 0 1rem 0.5rem;
    }

    .nestedChildContainer {
        min-height: 90vh;
    }
}

@media only screen and (max-width: 1024px) {
    .parentContainer {
        flex-direction: column;
        height: 96vh;
    }

    .outletContainer {
        padding: 0 0.5rem;
        position: relative;
        top: 5rem;
        overflow-y: auto;
    }

    .outletContainer::-webkit-scrollbar{
        display: none;
    }

    .menuBarContainer {
        flex: 0;
    }

    .contentContainer {
        padding-right: 0;
    }

    .contentContainer::-webkit-scrollbar {
        display: none;
    }

    .kycPendingBannerContainer > img,
    .loudspeaker {
        display: none;
    }

    .kycPendingBannerContainer > p,
    .kycBanner > p {
        font-size: 0.5rem;
        line-height: 1;
    }

    .kycButton {
        cursor: pointer;
        height: 2rem;
        width: 6rem;
    }

    .kycBannerDev {
        margin-right: 0;
        padding-left: 0;
        font-size: 0.5rem;
    }

    .bannerContainer,
    .kycPendingBannerContainer{
        z-index: 11 !important;
    }

    .bannerContainer p {
        font-size: 0.5rem;
    }
}

@media screen and (min-width: 500px) and (max-width: 850px) {
    .nestedChildContainer {
        height: 98%;
    }
}
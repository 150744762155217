.mainContainer {
    overflow: auto;
    max-height: 100%;
    scrollbar-width: none;
}

.roundBoxContainer {
    display: flex;
    border-radius: 30px;
    width: 90%;
    background: var(--white);
    margin-top: 2rem;
    margin-bottom: 2rem;
    margin-left: 2rem;
    box-shadow: var(--transparentBlackShadow);
    padding-top: 3rem;
    padding-bottom: 3rem;
}

.mainContainer::-webkit-scrollbar {
    display: none;
}

.leftContainer {
    width: 36.5vw;
    display: flex;
    flex-direction: column;
    position: relative;
    align-items: center;
    flex: 1;

}
.rechargeSuccess {
    position: relative;
    top: 0.75vh;
    text-align: center;
    color: var(--blue);
}
.leftContainer p {
    margin-top: -1.5vh;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 300;
    font-size: 1.2vw;
    line-height: 3.3vh;
    width: 18.3vw;
    text-align: center;
    color: var(--darkSilver);
}

.image{
    position: relative;
    top: -6vh;

}
.correctLogo {
    position: relative;
    top: 3vh;
}

.rightContainer {
    flex: 1;
    display: flex;
    flex-direction: column;
    padding-right: 1.5rem;
    justify-content: center;
}

.dataRow {
    display: flex;
    padding: 1rem;
    border-bottom: var(--gray);
}

.label {
    font-weight: bold;
    color: var(--gray);
    flex: 1;
}

.value {
    font-weight: bold;
    color: var(--darkBlue);
    align-self: flex-end;
}

.footDataContainer {
    margin-top: 2rem;
}

.remark {
    font-weight: bold;
    font-size: 1.2rem;
    color: var(--gray);
}

.yellowTextContainer {
    display: flex;
    color: var(--yellow);
    font-weight: bold;
}

.remarkText {
    margin-top: 1.5rem;
    color: var(--gray);
}

@media only screen and (max-width: 1024px) {
    .roundBoxContainer{
        flex-direction: column;
        margin: 0.5rem 0.5rem 0.5rem 1.5rem;
        padding-bottom: 1rem;
        padding-top: 2rem;
    }

    .leftContainer {
        width: 100%;
    }

    .leftContainer p {
        width: 100%;
        font-size: 0.7rem;
    }

    .rightContainer {
        padding-right: 0;
    }

    .footDataContainer {
        width: 80vw;
        padding:0 0.5rem;

    }

    .yellowTextContainer{
        width: 85vw;
        gap: 0.5rem;
    }

    .yellowRight {
        flex: 1;
        font-size: 0.8rem;
        display: flex;
        justify-content: flex-end;
        margin-left: 0;
    }

    .yellowLeft {
        flex: 0.5;
    }

    .remarkText{
        text-align: center;
    }

    .roundBoxContainer {
        margin-bottom: 3rem;
    }
}
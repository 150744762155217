.ipTrunkBaseContainer {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.ipTrunkContainer {
    height: 90%;
    border-radius: 20px;
    background: var(--white);
    box-shadow: 13px 17px 23px -22px rgba(0, 0, 0, 0.25);
}

.arrowDownIcon {
    transform: rotateZ(-90deg);
    font-size: .8rem !important;
    margin-left: .5rem;
    cursor: pointer;
}

.dropdownListContainer {
    position: absolute;
    margin-top: 0;
    margin-left: 6%;
    background: var(--white);
    padding: 1rem;
    z-index: 2;
    box-shadow: var(--transparentBlackShadow);
    border-radius: 10px;
}

.dropdownListContainer h5 {
    text-align: left;
    margin-bottom: 0;
    margin-top: 0;
}

.ipContainer {
    text-align: left;
    padding-bottom: .2rem;
    padding-top: .2rem;
}

.ipContainer:not(:last-child) {
    border-bottom: var(--grayBorder);
}

.upward {
    transform: rotateZ(90deg) translateX(5px);
}

.rowHeader {
    display: flex;
    justify-content: center;
    padding-top: 0.2em;
}

.toolTip {
    text-align: center;
}

.toolTip > a {
    color: var(--white);
}

.disable {
    color: var(--lightGray);
}

.enable {
    color: var(--gray);
}

.switch {
    position: relative;
}

.disabledSwitch:active {
    pointer-events: none;
}

.switch input {
    opacity: 0;
    height: 0;
    width: 0;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    height: 12px;
    width: 22px;
    background-color: var(--white);
    -webkit-transition: .4s;
    transition: .4s;
}

.slider:before {
    position: absolute;
    content: "";
    height: 6px;
    width: 6px;
    left: 1.5px;
    top: 1.2px;
    background-color: var(--white);
    -webkit-transition: .4s;
    transition: .4s;
}

.sliderBlue:before {
    border: 2px solid var(--blue);
}

.sliderGray:before {
    border: 2px solid var(--lightGray);
}

input:checked + .slider {
    background-color: var(--blue);
}

input:checked + .round {
    border: 2px solid var(--blue);
}
input:focus + .sliderBlue {
    box-shadow: 0 0 1px var(--blue);
}

input:focus + .sliderGray {
    box-shadow: 0 0 1px var(--lightGray);
}

input:checked + .slider:before {
    -ms-transform: translateX(10px);
    transform: translateX(10px);
    border-color: var(--white);
}

.slider.round {
    border-radius: 8px;
    border: 2px solid var(--blue);
}

.sliderGray.round {
    border: 2px solid var(--lightGray);
}

.sliderBlue.round {
    border: 2px solid var(--blue);
}

.slider.round:before {
    border-radius: 50%;
}

.roundButton {
    border-radius: 30px;
    border: 0;
    background: var(--blue);
    color: var(--white);
    width: fit-content;
    cursor: pointer;

    align-items: center;
}

.addIPBtn {
    display: flex;
    align-items: center;
    padding: 0.1em 0.5em;
}

.addIPBtn span {
    padding-left: 0.3em;
    font-size: 0.9vw;
}

.addIPBtn svg {
    font-size: 1vw;
}

.IPStateChangeDialog {
    display: flex;
    flex-direction: column;
    text-align: center;

    background-color: var(--white);
    padding: 0.5rem;
    border-radius: 10px;
    height: 100%;
}

.IPStateChangeDialogButtonContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: 0.5em;
}

.ipStateChangeError {
    color: var(--red);
}

@media only screen and (max-width: 480px) {
    .addIPBtn svg {
        font-size: 0.7rem;
    }

    .addIPBtn span {
        font-size: 2dvw;
    }

    .addIPBtn {
        padding: 0.1em 0.2em;
    }
}

@media only screen and (min-width: 480px) and (max-width: 1024px) {
    .addIPBtn svg {
        font-size: 1rem;
    }

    .addIPBtn span {
        font-size: 1.5vw;
    }
}
.skyInput {
    width: 100%;
    height: 2rem;
    border-radius: 40px;
    border: 1px solid var(--blue);
    margin-top: 8px;
    margin-left: -.1rem;
}

.errorInput {
    border: 1px solid var(--red);;
}

.skyInputLabel {
    font-size: .8rem;
    font-weight: 700;
    font-style: normal;
    color: rgba(0, 0, 0, 0.59);
    line-height: 17px;
}
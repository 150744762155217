.loaderContainer {
    width: 25rem;
    z-index: 9999;
}

.loader {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 20px;
}

.required {
    color: var(--red);
}

.formLabel {
    font-size: .8rem;
    font-weight: 700;
    font-style: normal;
    color: var(--yellow);
    line-height: 17px;
}

.logoImage {
    margin-left: calc(50% - 2.5rem);
    margin-top: -4rem;
    margin-bottom: 2rem;
    width: 5rem;
    border-radius: 50%;
}

.logoImageLoading {
    border: .4rem solid var(--white);
    margin-left: calc(50% - 2.9rem);
}

.text {
    font-size: 1.8rem;
    font-weight: 500;
    color: var(--yellow);
    text-align: center;
    margin-bottom: 1rem;
}

.outerFieldContainer {
    margin-top: 0.8rem;
    width: 100%;
}

.fieldContainer {
    margin: 0.5em 0.5em 1em 0.5em;
}

.contactNumberFieldContainer {
    display: flex;
}

.contactNumberFieldContainer > div {
    display: flex;
    justify-content: center;
    background-color: rgba(255, 255, 255, 0.2);
    background-position: 3.5rem;
    background-repeat: no-repeat;
    border: var(--lavenderIndigo);
    border-radius: 40px 0 0 40px;
    color: var(--white);
    cursor: pointer;
    outline: none;
    padding: 0.4rem;
    width: 6em;
    margin-top: 8px;
}

.formInput {
    width: 100%;
    height: 35px;
    border-radius: 40px;
    border: none;
    margin-top: 8px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
    color: var(--white);
    background-color: rgba(255, 255, 255, 0.2);
}

.errorFormInput {
    border: 1px solid var(--red);
}

.passwordField {
    display: flex;
    justify-content: center;
    align-items: center;
}

.passwordField svg {
    margin-left: 3px;
    margin-top: 8px;
    font-size: 1.5rem;
    color: var(--white);
}

.visibilityIcon > svg {
    margin-top: 15px;
    margin-left: -1.4em;
    cursor: pointer;
}

.passwordInputField {
    width: 90%;
}

.contactNumberInput {
    border-radius: 0 40px 40px 0;
}

input {
    text-indent: 3%;
}

input::placeholder {
    font-size: 11px;
    letter-spacing: 0.8px;
}

.dialCodeSelector > div {
    width: 90%;
    box-shadow: none;
}

.dialCodeSelector > div > input {
    width: 98%;
    font-size: 0.8em;
    padding: 8px 6px;
    margin-top: 0;
}

.dialCodeSelector > div > div {
    width: 100%;
    font-size: 0.8em;
}

.formButton {
    background: var(--blue);
    width: 100%;
    margin-left: 5%;
    border-radius: 20px;
    margin-top: 2rem;
    color: var(--white);
    border: 0;
    padding: .35rem;
    margin-bottom: 3rem;
    cursor: pointer;
}

.fieldsRow {
    display: flex;
    justify-content: space-evenly;
}

.fieldsRow > div {
    flex-grow: 1;
    flex-basis: 0;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
}

input[type=number] {
    -moz-appearance: textfield;
}

.errorMessage {
    position: absolute;
    margin-top: 2px;
    margin-left: 0.5em;
    color: var(--white);
    font-size: 0.8rem;
}

.buttonContainer {
    margin: 1.5em 0.5em 0 0.5em;
    padding-bottom: 1.5rem;
}

.successMessageContainer {
    background: var(--white);
    padding: 1rem;
    text-align: center;
    font-family: Roboto, serif;
    color: var(--blue);
    font-weight: 500;
}

.nameContainer {
    margin-top: -1rem;
}

.dialogueMessage {
    font-size: .82rem;
    color: var(--gray);
}

.successImage {
    max-width: 80px;
    margin-bottom: 1rem;
}

.dialogButtonContainer {
    margin-top: 0.5rem;
}

.button {
    width: 100%;
    border-radius: 20px;
    background: linear-gradient(to right, #FBBF13, #FB7005);
    padding: 0.5rem;
    border: none;
    color: var(--white);
    box-shadow: 2px 4px 10px rgba(0, 0, 0, 0.3);
}

.disabled {
    background: linear-gradient(to right, rgba(251, 191, 19, 0.8), rgba(251, 112, 5, 0.5));
}

@media only screen and (max-width: 1024px) {
    .fieldsRow {
        flex-direction: column;
    }

    .dialCodeSelector > div {
        width: 85%;
    }
}
:root {
    --blue: #6F6AF8;
    --darkBlue: #503d88;
    --blueViolet: #570BB9;
    --coldPurple: #AF9CFF;
    --cyan: #99CCED;
    --warmBlue: #5353f5;
    --periwinkleGrey: #cdc6f2;
    --gray: #727272;
    --darkGray: #383636;
    --lightGray: #cdcdce;
    --indigo: #6c63e0;
    --purple: #947AFF;
    --bluishPurple:#B68AFF;
    --white: #FFFFFF;
    --transparentWhite: #ffffff00;
    --mediumDark: #32475CAD;
    --titenWhite: #f1efff;
    --purplishPink: #ad46bc;
    --lightPurple: #DB6AF7;
    --lavenderIndigo: #9C4EFF;
    --green: #0fce0f;
    --lightGreen: #d4fad4;
    --darkGreen: #18b328;
    --darkBlackishGreen: #05650c;
    --lavenderBlue: #E3CDFF;
    --lightYellow: #a5c251;
    --red: #FF0000FF;
    --darkRed: #8B0000FF;
    --yellow: #FFC700;
    --darkSilver: #ACACAC;
    --grayBorder: 1px solid var(--gray);
    --semiTransparentDarkBlue: rgba(50, 71, 92, 0.6);
    --semiTransparentWhite: rgba(255, 255, 255, 0.71);
    --coldPurpleShadow: 13px 17px 23px -22px rgba(166, 163, 245, 6);
    --brightLavenderShadow: 13px 17px 23px -22px rgba(166, 163, 245, 0.25);
    --bilobaFlowerShadow: 4px 1px 9px 3px rgba(166, 163, 245, 0.7);
    --dullLavenderShadow: 5px 16px 14px -10px #A6A3F5;
    --semiTransparentLavender: 1.3rem 1.7rem 2.1rem -2.2rem #A6A3F5;
    --blackShadow: 0px 7px 19px -11px #000000;
    --semiTransparentBlackShadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    --blurTransparentShadow: -4px 14px 9px 0px rgba(0, 0, 0, 0.25);
    --semiDarkShadow: 8px 8px 38px -12px rgba(0, 0, 0, 0.25);
    --transparentBlackShadow: 0 3px 10px rgb(0 0 0 / 0.2) ;
    --whitishShadow: 5px 0 0 var(--white) inset;
    --paleLavender: rgba(111, 106, 248, 0.06);
    --darkShadeShadow: 0 4px 9px rgba(0, 0, 0, 0.18);
    --darkBlackShadow:0 5px 9px rgba(0, 0, 0, .14);
    --transparentShadow:0 5px 9px rgba(0, 0, 0, 0);
    --semiTransparentDarkShadow: 13px 17px 23px -22px rgba(0, 0, 0, 0.25);
    --lightPurpleShadow: 10px 11px 21px rgba(166, 163, 245, 0.67);
    --slateBlackShadow: 1px 3px 6px rgba(0, 0, 0, 0.1);
    --highContrastDarkShadow:0 4px 4px rgba(0, 0, 0, 0.1);
    --semiBlackShadow: 0 -2px 4px rgba(0, 0, 0, 0.25);
    --transparentBlueShadow: -2px 58px 43px rgba(62, 69, 244, 0.1);
    --rateScreenBackground:linear-gradient(180deg, rgba(223, 224, 255, 0.71) 3.79%, rgba(255, 255, 255, 0) 119.86%);
}
.container{
    display: flex;
    justify-content: center;
    align-items: center;
    height: calc(100vh - 10rem);
}

.wrapperContainer{
    background-color: var(--white);
    height: 100%;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    min-width: 80%;
}

.image{
    height: 70%;
    object-fit: cover;
}

.textContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
}

.title {
    color: var(--gray);
    font-size: 1.5rem;
    margin: 0;
}

.buttonContainer {
    display: flex;
    justify-content: center;
    width: 100%;
}

@media only screen and (max-width: 1024px) {
    .wrapperContainer {
        height: 90%;
    }
}

@media only screen and (max-width: 500px) {
    .container {
        height: calc(100vh - 15rem);
    }

    .wrapperContainer {
        height: 80%;
        gap: 3rem;
    }

    .image{
        height: 50%;
    }

    .title{
        font-size: 1.2rem;
    }
}
.serviceArea {
    height: 100%;
    overflow: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
    margin-top: 1rem;
}

.serviceArea::-webkit-scrollbar {
    display: none;
}

@media only screen and (max-width: 480px) {
    .serviceArea {
        height: 70dvh;
        margin-top: 2rem;
    }
}

@media only screen and (min-width: 480px) and (max-width: 1080px) {
    .serviceArea {
        height: 68dvh;
    }
}
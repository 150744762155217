.container {
    overflow: hidden;
    background-color: #FFFFFF;
}

.containerWrapper {
    overflow: auto;
    max-height: 100vh;
}

.maxWidthWrapper {
    max-width: 1200px;
    margin: 1rem auto;
    padding: 1rem;
}

.headerContainer{
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid var(--gray);
    padding-bottom: 1rem;
}

.headerText {
    font-size: 3rem;
    color: var(--gray);
}

.headerDescription{
    font-size: 1rem;
    color: var(--gray);
    font-family: "Roboto", sans-serif;
    padding-top: 10px;
}

.logo {
    width: 5rem;
    height: 5rem;
}

nav ul {
    list-style-type: none;
    padding: 0;
}

.titleContent {
    position: relative;
    text-decoration: none;
    color: var(--blue);
    font-family: "Roboto", sans-serif;
    padding-bottom: 0.2rem;
    display: inline-block;
    font-size: 1.2rem;
    margin-bottom: 0.5rem;
    opacity: 0.8;
    background-color: transparent;
    border: none;
    cursor: pointer;
    text-align: left;
}

.titleContent:hover{
    opacity: 1;
}

.titleContent::after {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    height: 2px;
    width: 0;
    background-color: var(--blue);
    transition: width 0.3s ease;
}

.titleContent:hover::after {
    width: 100%;
    opacity: 1;
}

.contentContainer {
    margin-top: 1rem;
}

.content {
    margin-top: 2rem;
}

.title {
    margin-bottom: 0.4rem;
    font-family: "Roboto", sans-serif;
}

.description {
    font-family: "Roboto", sans-serif;
    line-height: 1.4rem;
    color: var(--gray);
    text-align: justify;
    margin-top: 1rem;
}

.scrollTopButton{
    position: fixed;
    bottom: 40px;
    right: 80px;
    padding: 0.8rem;
    background: linear-gradient(to right, #3b82f6, #8b5cf6);
    color: #ffffff;
    border: none;
    border-radius: 50%;
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    animation: pulse 2s infinite;
}

.scroll-top-button:hover {
    background: linear-gradient(to right, #2563eb, #7c3aed);
    transform: scale(1.1);
}

@keyframes pulse {
    0% {
        box-shadow: 0 0 0 0 rgba(59, 130, 246, 0.7);
    }
    70% {
        box-shadow: 0 0 0 10px rgba(59, 130, 246, 0);
    }
    100% {
        box-shadow: 0 0 0 0 rgba(59, 130, 246, 0);
    }
}

.icon{
    width: 2rem;
    height: 2rem;
}

@media only screen and (max-width: 1024px) {
    .scrollTopButton{
        right: 40px;
    }

    .maxWidthWrapper{
        margin-bottom: 8rem;
    }
}

@media only screen and (min-width: 426px) and (max-width: 1023px) {
    .headerText {
        font-size: 2rem;
    }

    .logo {
        width: 3rem;
        height: 3rem;
    }

    .icon{
        width: 1.5rem;
        height: 1.5rem;
    }
}

@media only screen and (max-width: 425px) {
    .headerText {
        font-size: 1.6rem;
    }

    .logo {
        width: 3rem;
        height: 3rem;
    }

    .icon{
        width: 1.5rem;
        height: 1.5rem;
    }
}

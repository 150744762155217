.widgetContainer {
    width: calc(100% - 2rem);
    height: calc(100% - 2rem);
    padding: 1rem;
    box-shadow: var(--transparentBlackShadow);
    border-radius: 2rem;
    overflow: hidden;
    min-width: 0;
}

.header {
    margin-bottom: .5rem;
    color: var(--indigo);
    text-align: center;
    font-size: 1vw;
    font-weight: 700;
}

.text {
    font-size: 0.55rem;
    display: flex;
    color: var(--gray);
    justify-content: center;
}

@media only screen and (max-width: 480px) {
    .widgetContainer {
        padding: 0.3rem 0.5rem;
        width: 80vw;
    }
    .header {
        padding-top: 0.3rem;
        font-size: 1rem;
    }
}

@media only screen and (min-width: 481px) and (max-width: 1024px) {
    .widgetContainer {
        height: 55vh;
    }

    .header {
        font-size: 1.2rem;
    }
}

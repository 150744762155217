.signUpContainer {
    z-index: 5;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    overflow: auto;
    justify-content: space-evenly;
    flex-direction: column;
    padding-bottom: 2rem;
    padding-top: 2rem;
    gap: 1rem;
}

.container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-top: 1rem;
}

.logo {
    width: 4rem;
    height: 4rem;
}

.signUpWrapperContainer {
    background-color: rgba(255, 255, 255, 0.1);
    padding: 1rem 1.5rem;
    -webkit-backdrop-filter: blur(2px);
    border-radius: 20px;
    box-shadow: 5px 5px 12px 2px rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    transition: all 0.3s ease-in-out;
    z-index: 4;
    width: 80%;
    margin-top: 1rem;
    position: relative;
}

.signUpWrapperContainer::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.1);
    z-index: -1;
    border-radius: 20px;
    backdrop-filter: blur(2px);
    -webkit-backdrop-filter: blur(2px);
}

.loginText {
    border-radius: 20px;
    padding: 0.3rem 3rem;
    font-weight: bold;
    z-index: 2;
    border: none;
    cursor: pointer;
    background: transparent;
    color: var(--white);
    font-size: 1rem;
}

.loginText:hover{
    text-decoration: underline;
    color: var(--yellow);
}

.loginFooter {
    text-align: center;
    margin-top: 3rem;
    margin-bottom: 2rem;
}

.loginFooterDescription {
    color: var(--yellow);
    font-weight: 400;
    font-size: 1.3rem;
}

.loginFooterText {
    color: var(--white);
    font-weight: 500;
    font-size: 1.2rem;
}

.loginPromptText{
    color: var(--white);
    font-size: 1rem;
}

@media only screen and (max-width: 1150px) {
    .signUpWrapperContainer {
        width: 80%;
    }
}

@media only screen and (max-width: 1024px) {
    .signUpWrapperContainer {
        width: 80%;
        padding: 1rem;
    }
}

@media only screen and (max-width: 768px) {
    .signUpContainer {
        gap: 2rem;
        padding-top: 0;
    }

    .loginText {
        text-decoration: underline;
        color: var(--yellow);
    }
}

@media only screen and (max-width: 375px) {
    .signUpWrapperContainer {
        width: 85%;
    }
}
.container {
    height: 88%
}

.tableContainer {
    background: var(--white);
    border-radius: 22px;
    box-shadow: var(--semiTransparentDarkShadow);
    height: 100%;
}

.payButton {
    background-color: var(--blue);
    color: #ffffff;
    border: none;
    padding: 0.2rem 0.5rem;
    border-radius: 5px;
    cursor: pointer;
    width: 4.5rem;
    text-align: center;
}

.payButton:disabled {
    background-color: var(--gray);
    color: var(--white);
    cursor: not-allowed;
    opacity: 0.5;
    width: 4.5rem;
    text-align: center;
}

.previewContainer {
    background: white;
    border-radius: 10px;
    padding: 1rem;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.previewHeader {
    color: #6c5ce7;
    font-size: 1.2rem;
    font-weight: 500;
    text-align: center;
    margin: 0.5rem 0;
}

.previewContent {
    display: flex;
    flex-direction: column;
    gap: 1.2rem;
    margin-bottom: 2rem;
}

.previewRow {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 2rem;
    padding: 0.5rem 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.previewLabel {
    color: #666;
    font-size: 0.9rem;
}

.previewValuePurple {
    color: #6c5ce7;
    font-weight: 500;
    font-size: 0.9rem;
}

.buttonContainer {
    margin-top: 1rem;
    margin-bottom: 0.5rem;
    display: flex;
    justify-content: center;
    gap: 1rem;
}

.dialogOuterContainer {
    background: var(--white);
    padding: 0.5rem;
    border-radius: 10px;
    height: 100%;
}

.dialogContainer {
    display: flex;
    align-items: center;
    flex-direction: column;
}

.dialogText {
    color: var(--gray);
    font-size: .9rem;
    padding-top: 1em;
    padding-bottom: 0.5em;
    text-align: center;
    font-family: Roboto, serif;
    line-height: 1.3rem;
}

.bold {
    font-weight: bold;
}

.status {
    color: var(--white);
    padding: 0.2rem 0.5rem;
    border-radius: 5px;
}

.unpaid {
    background-color: #FECACA;
    color: #9B1C31;
}

.paid {
    background-color: #A7F3D0;
    color: #166534;
}

.options {
    display: flex;
    gap: 1rem;
    justify-content: center;
    align-items: center;
}

.icon {
    color: var(--blue);
    cursor: pointer;
}

@media screen and (max-width: 720px) {
    .payButton {
        font-size: 0.6rem;
        width: 3.5rem;
    }

    .payButton:disabled {
        width: 3.5rem;
    }

    .options {
        gap: 0.3rem;
    }
}
.userTrunkContainer {
    height: 90%;
    border-radius: 20px;
    background: var(--white);
    box-shadow: var(--semiTransparentDarkShadow);
}

.arrowDownIcon {
    transform: rotateZ(-90deg);
    font-size: .8rem !important;
    margin-left: .5rem;
    cursor: pointer;
}

.dropdownTableContainer {
    position: absolute;
    margin-top: 0;
    background: var(--white);
    padding: 1rem;
    z-index: 2;
    box-shadow: var(--transparentBlackShadow);
    border-radius: 10px;
}

.dropdownTableContainer th:first-of-type {
    border-right: var(--grayBorder);
}

.dropdownTableContainer th {
    border-bottom: var(--grayBorder);
    color: var(--blue);
}

.dropdownTableContainer tr td:first-of-type {
    border-right: var(--grayBorder);
}

.dropdownTableContainer th, .dropdownTableContainer td {
    padding: .1rem;
}

.upward {
    transform: rotateZ(90deg) translateX(5px);
}

.dot {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: var(--green);
    margin: auto;
}

.green {
    background: var(--green);
}

.red {
    background: var(--red);
}
.container {
    position: relative;
    height: 100vh;
    width: 100%;
    overflow: hidden;
    background: linear-gradient(-45deg, #1C38CD, #D83EFF, #6E3BE3, #AB3DF3);
    background-size: 300% 300%;
    animation: gradient 15s ease infinite;
}

@keyframes gradient {
    0% {
        background-position: 0% 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0% 50%;
    }
}


.wrapper {
    position: relative;
    height: 100%;
    width: 100%;
    z-index: 2;
}

.slideRight {
    flex-direction: row;
}

.welcomeSection {
    position: absolute;
    top: 0;
    left: 0;
    width: 50vw;
    height: 100%;
    background-color: white;
    transition: transform 0.7s ease-in-out;
    z-index: 2;
}

.welcomeContent {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.welcomeTitle {
    font-family: 'Inspiration', cursive;
    font-style: normal;
    font-size: 6rem;
    text-align: center;
    color: var(--blue);
    padding-top: 1.5rem;
    overflow: hidden;
}

@font-face {
    font-family: 'Inspiration';
    src: url('../../../public/Inspiration-Regular.ttf');
    font-weight: normal;
    font-style: normal;
}

.welcomeLetter {
    display: inline-block;
    opacity: 0;
    transform: translateX(-100%);
    animation: revealLetter 0.5s cubic-bezier(0.25, 0.1, 0.25, 1) forwards;
}

@keyframes revealLetter {
    to {
        opacity: 1;
        transform: translateX(0);
    }
}

.welcomeLetter:nth-child(1) {
    animation-delay: 0.1s;
}

.welcomeLetter:nth-child(2) {
    animation-delay: 0.2s;
}

.welcomeLetter:nth-child(3) {
    animation-delay: 0.3s;
}

.welcomeLetter:nth-child(4) {
    animation-delay: 0.4s;
}

.welcomeLetter:nth-child(5) {
    animation-delay: 0.5s;
}

.welcomeLetter:nth-child(6) {
    animation-delay: 0.6s;
}

.welcomeLetter:nth-child(7) {
    animation-delay: 0.7s;
}

.welcomeImage {
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    scale: 0.8;
    border-radius: 35%;
}

.registerImage {
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    height: 65%;
    display: block;
    margin: 0 auto;
}

.formSection {
    position: absolute;
    top: 0;
    right: 0;
    width: 50vw;
    height: 100%;
    transition: transform 0.7s ease-in-out;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
}

.wrapper.slideRight .welcomeSection {
    transform: translateX(100%);
}

.wrapper.slideRight .formSection {
    transform: translateX(-100%);
}

.formContent {
    width: 50vw;
}

.animated-background {
    position: absolute;
    inset: 0;
    overflow: hidden;
}

.floatingIcon {
    position: absolute;
    color: white;
    opacity: 0.1;
    animation: diagonal-float 20s linear infinite;
}

.floatingIcon.small {
    font-size: 1rem;
    z-index: 1;
}

.floatingIcon.medium {
    font-size: 2rem;
    z-index: 2;
}

.floatingIcon.large {
    font-size: 4rem;
    z-index: 3;
}

@keyframes diagonal-float {
    0% {
        transform: translate(0, 0);
    }
    100% {
        transform: translate(100vw, -100vh);
    }
}

.floatingIcon:nth-child(even) {
    animation-direction: reverse;
    animation-duration: 25s;
}

.floatingIcon:nth-child(3n) {
    animation-duration: 22s;
}

.floatingIcon:nth-child(5n) {
    animation-duration: 28s;
}

.authMobileScreen{
    display: none;
}

@media screen and (max-width: 1150px) and (min-width: 750px) {
    .registerImage {
        height: 50%;
    }
}

@media screen and (max-width: 768px) {
    .wrapper {
        display: none;
    }

    .authMobileScreen{
        display: block;
        position: relative;
        height: 100%;
        width: 100%;
        z-index: 2;
        overflow: auto;
    }

    .container {
        overflow: hidden;
    }
}
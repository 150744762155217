.csrContainer{
    display: flex;
    height: 90%;
}

.calendarContainer {
    flex: .5;
    height: 90%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.dataContainer {
    position: relative;
    flex: 1;
    height: 90%;
    margin-left: 4rem;
    border-radius: 20px;
    background: var(--white);
    box-shadow: var(--semiTransparentDarkShadow);
}
.buttonContainer {
    position: absolute;
    right: 1rem;
}

@media screen and (max-width: 850px) {
    .csrContainer {
        flex-direction: column;
        padding-bottom: 3rem;
        height: fit-content;
    }

    .dataContainer {
        margin: 1.5rem 0.5rem 0.5rem;
    }

    .buttonContainer {
        margin-top: 1rem;
    }

}

@media screen and (min-width: 700px) and (max-width: 1024px) {
    .dataContainer {
        margin-left: 1rem;
    }
}
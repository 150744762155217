.tabContainer {
    height: 8vh;
    display: flex;
    color: var(--purple);
    align-items: center;
}

.mainMenuHeader {
    font-size: 2.1rem;
    color: var(--blue);
    margin-bottom: 1rem;
    font-weight: 500;
}

.tabButtonsContainer {
    margin-left: .5rem;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 0.5rem;
}

.tab {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 14px;
    height: fit-content;
    background: var(--titenWhite);
    filter: drop-shadow(var(--semiTransparentBlackShadow));
    -webkit-filter: drop-shadow(var(--semiTransparentBlackShadow));
    color: var(--purple);
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-size: 0.8rem;
    cursor: pointer;
    margin-left: 1rem;
    padding: .5rem;
}

.active {
    color: var(--white);
    background: var(--purple);
}

@media only screen and (max-width: 480px) {
    .tabContainer {
        flex-direction: column;
        padding-bottom: 1rem;
    }

    .mainMenuHeader {
        font-size: 1.5rem;
        width: 90dvw;
        padding-left: 0.5rem;
        margin-bottom: 0.5rem;
    }
}

@media only screen and (min-width: 480px) and (max-width: 520px) {
    .tabContainer {
        margin-top: 0.5rem;
    }
    .tab {
        font-size: 2.2vw;
    }
}
.subMenuContainer {
    display: flex;
    flex-direction: column;
}

.subMenuHeader {
    font-size: 2.1rem;
    color: var(--blue);
    margin-bottom: 1rem;
    font-weight: 500;
}

@media only screen and (max-width: 480px) {
    .subMenuHeader {
        font-size: 5vw;
        text-align: center;
        margin-bottom: 0.5rem;
    }

    .subMenuContainer {
        height: 75vh;
    }
}

@media only screen and (min-width: 481px) and (max-width: 1024px) {
    .subMenuHeader {
        font-size: 4vw;
        text-align: center;
        margin-bottom: 0.5rem;
    }

    .subMenuContainer {
        height: 65vh;
    }
}
.infoTextContainer {
    text-align: center;
    color: var(--gray);
    font-size: .6rem;
    margin-bottom: 1rem;
}

.resetPasswordContainer {
    background: white;
    padding: .5rem 1.5rem;
    border-radius: 10px;
}

.resetPasswordFormRow {
    margin-top: .2rem;
    margin-bottom: 1.8rem;
}

.buttonRow {
    display: flex;
    justify-content: center;
}

.buttonWrapper {
    margin-left: 1rem;
}
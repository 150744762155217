.container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    overflow: auto;
    scrollbar-width: none;
}

.container::-webkit-scrollbar {
    display: none;
}

.roundWhiteContainer {
    display: flex;
    flex-direction: column;
    width: fit-content;
    height: fit-content;
    align-items: center;
    border-radius: 2vw;
    background: var(--white);
    padding: 2rem;
}

.headerText {
    color: var(--blue);
    font-family: Roboto;
    font-size: 2rem;
}

.failureImage {
    height: 25vh;
}

.messageHeader {
    font-weight: bold;
    font-size: 1.1rem;
    color: var(--blue);
    text-align: center;
}

.messageSubHeader {
    font-size: .8rem;
    color: var(--blue);
    text-align: center;
}

.messageText {
    color: var(--gray);
    text-align: center;
}

@media only screen and (max-width: 480px) {
    .container {
        max-height: 75vh;
        height: auto;
    }

    .roundWhiteContainer {
        padding: 1rem;
    }

    .headerText {
        font-size: 1.5rem;
        margin: 0.5rem 0;
    }
}
.container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    overflow: auto;
    scrollbar-width: none;
}

.container::-webkit-scrollbar {
    display: none;
}

.roundWhiteContainer {
    display: flex;
    flex-direction: column;
    width: fit-content;
    height: fit-content;
    align-items: center;
    border-radius: 2vw;
    background: var(--white);
    padding: 2rem;
}

.headerText {
    color: var(--blue);
    font-family: Roboto, serif;
    font-size: 2rem;
    margin-bottom: 0.2rem;
}

.pendingImage {
    height: 25vh;
}
.pendingText {
    font-size: 1.1rem;
    font-weight: 500;
    color: var(--gray);
}

.messageHeader {
    font-weight: bold;
    font-size: 1.1rem;
    color: var(--green);
    text-align: center;
}

.bottomContainer{
    display: flex;
    text-align: center;
}
.supportTest{
    display: flex;
    text-align: center;
    color: var(--gray);
}
.emailText{
    color: var(--blueViolet);
}

@media only screen and (max-width: 480px) {
    .container {
        max-height: 75vh;
        height: auto;
    }

    .roundWhiteContainer {
        padding: 1rem;
    }

    .headerText {
        font-size: 1.5rem;
        margin: 0.5rem 0;
    }
}
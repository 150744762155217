.cliDetailsTable {
    width: 100%;
    border-collapse: collapse;
    margin-top: 8px;
}

.cliDetailsTable th {
    border: 1px solid var(--white);
    padding: .2rem;
    text-align: left;
    background-color: var(--warmBlue);
}

.cliDetailsTable td {
    border: 1px solid var(--white);
    padding: .5rem;
}

.cliDetailsTable td:first-child {
    text-align: center;
    width: 30%;
}

.cliDetailsTable td:last-child {
    width: 70%;
}

.cliDetailsContainer {
    cursor: pointer;
    color: var(--warmBlue);
    display: inline-block;
    margin: 0 8px;
}
.cliDetailsContainer:hover .arrowDownIcon {
    transform: rotateZ(90deg) translateX(5px);
    transition: transform 0.3s ease;
}

.arrowDownIcon {
    transform: rotateZ(-90deg);
    font-size: .8rem !important;
    margin-left: .5rem;
    cursor: pointer;
}

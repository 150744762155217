.dropdownContainer {
    position: absolute;
    width: inherit;

    box-shadow: var(--transparentBlackShadow);
    background-color: var(--white);

    border-radius: 10px;
    margin-top: 0.3em;
    padding-bottom: 0.5em;
    z-index: 20;
}

.searchBar {
    padding: 0;
}

.searchInput {
    width: 95%;
    position: sticky;
    top: 0;

    z-index: 2;

    box-shadow: var(--transparentBlackShadow);

    outline: none;
    padding: 6px;
    margin-bottom: 4px;
    border-radius: 10px;
    border: 0;
    border-bottom: 2px solid var(--lightGray);

    font-weight: normal;
    font-size: 0.9em;
}

.dropdownOptionContainer {
    overflow-y: auto;
    overflow-x: hidden;
    max-height: 8em;
}

.dropdownOptionContainer > div {
    padding: 0.5em 1em;
    font-weight: normal;
    font-size: 0.9em;
    cursor: pointer;
}

.dropdownOptionContainer > div:hover {
    box-shadow: var(--transparentBlackShadow);
}

.font {
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 500;
    letter-spacing: 0.009rem;
    color: var(--gray);
}

.dataNotFoundMessage {
    padding: 0.5em 1em;
    overflow-y: hidden;
}


.table {
    font-family: Arial, sans-serif;
    height: calc(100% - 1rem);
    white-space: nowrap;
    margin: auto auto 1rem;
}

.headerContainer {
    display: flex;
    box-shadow: var(--highContrastDarkShadow);
    border-radius: 20px;
    padding-left: 3%;
    padding-right: 3%;
}

.bodyContainer {
    height: calc(99% - 2.5rem);
    overflow-y: auto;
    overflow-x: hidden;
    -ms-overflow-style: none;
    scrollbar-width: none;
    padding-left: 3%;
    padding-right: 3%;
    text-align: center;
}

.emptyTableImage {
    margin-top: 2%;
    height: 80%;
}

.empty {
    align-items: center;
    justify-content: center;
    display: flex;
    padding: 1rem;
}

.emptyTextContainer {
    color:  var(--gray);
    font-size: .8rem;
}

.rowContainer {
    display: flex;
    border-width: 1px;
    border-style: solid;
    border-bottom: 1px solid transparent;
    border-image: linear-gradient(0.25turn, #ffffff00, #a9a3f1, #ffffff00);
    border-image-slice: 1;
    width:100%;
}

.headerCell {
    color: var(--purple);
    text-align: center;
    padding: 1rem 0.5rem;
    font-weight: bold;
    flex: 1;
    min-width: 0;
    font-size: 1vw;
}

.bodyCell {
    color: var(--gray);
    font-size: 0.8em;
    text-align: center;
    padding: .8rem 0.5rem;
    flex: 1;
    min-width: 0;
    text-wrap: balance;
    display: flex;
    justify-content: center;
    align-items: center;
}

.multiColorBar {
    margin-left: 3%;
    height: 8px;
    width: 94%;
    border-radius: 10px;
}

.threeColBar {
    background: repeating-linear-gradient(to right, #EAA8F3, #EAA8F3 33.33%, #6F6AF7 33.33%, #6F6AF7 66.67%, #FFD541 66.67%, #FFD541) 0 100%;
}

.fourColBar {
    background: repeating-linear-gradient(to right, #EAA8F3, #EAA8F3 25%, #6F6AF7 25%, #6F6AF7 50%, #FFD541 50%, #FFD541 75%, #EC82FC 75%, #EC82FC) 0 100%;
}

.fiveColBar {
    background: repeating-linear-gradient(to right, #EAA8F3, #EAA8F3 20%, #6F6AF7 20%, #6F6AF7 40%, #FFD541 40%, #FFD541 60%, #EC82FC 60%, #EC82FC 80%, #9F9BFA 80%, #9F9BFA) 0 100%;
}

.sixColBar {
    background: repeating-linear-gradient(to right, #99e4f9, #99e4f9 16.677%, #EAA8F3 16.677%, #EAA8F3 33.354%, #6F6AF7 33.354%, #6F6AF7 50.031%, #FFD541 50.031%, #FFD541 66.708%, #EC82FC 66.708%, #EC82FC 83.385%, #9F9BFA 83.385%, #9F9BFA) 0 100%;
}

.loading {
    background-size: 200% auto;
    animation: loadingAnimation 2s infinite;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
}

@keyframes loadingAnimation {
    0%   { background-position: 0 0; }
    100% { background-position: -200% 0; }
}

@media only screen and (max-width: 480px) {
    .headerCell {
        font-size: 0.6em;
    }

    .bodyCell {
        font-size: 0.7em;
    }
}

@media only screen and (min-width: 480px) and (max-width: 720px) {
    .headerCell {
        font-size: 1.7vw;
    }
}

@media only screen and (min-width: 720px) and (max-width: 1024px) {
    .headerCell {
        font-size: 1.5vw;
    }
}
.invoiceViewContainer {
    background: white;
    border-radius: 10px;
    padding: 1rem;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.dotContainer {
    height: calc(100vh - 120px);
    display: flex;
    justify-content: center;
    align-items: center;
}

.pdfContainer {
    height: calc(100vh - 120px);
    overflow: hidden;
}

.pdfViewer {
    height: 80%;
}

.button {
    display: flex;
    justify-content: center;
    margin: 0.5rem 0;
}

@media screen and (max-width: 720px) {
    .pdfContainer,
    .dotContainer {
        height: calc(100vh - 350px);
    }

    .pdfViewer {
        height: 90%;
    }

    .button {
        margin-top: 1rem;
    }
}
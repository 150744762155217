.signInContainer {
    z-index: 5;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    overflow: auto;
    justify-content: space-evenly;
    flex-direction: column;
}

.headerContainer,
.signFooter {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
    flex-direction: column;
}

.logo {
    width: 4rem;
    height: 4rem;
}

.loginWrapperContainer {
    background-color: rgba(255, 255, 255, 0.1);
    padding: 1rem 2rem;
    max-width: 25rem;
    width: 100%;
    border-radius: 20px;
    box-shadow: 1px 1px 9px 1px rgba(0, 0, 0, 0.3);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    transition: all 0.3s ease-in-out;
    z-index: 4;
    position: relative;
}

.loginWrapperContainer::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.1);
    z-index: -1;
    border-radius: 20px;
    backdrop-filter: blur(2px);
    -webkit-backdrop-filter: blur(2px);
}

.registerPromptText {
    color: var(--white);
    font-size: 1rem;
}

.registerText {
    border-radius: 20px;
    padding: 0.3rem 3rem;
    font-weight: bold;
    z-index: 2;
    border: none;
    cursor: pointer;
    background: transparent;
    color: var(--white);
    font-size: 1rem;
}

.registerText:hover {
    text-decoration: underline;
    color: var(--yellow);
}

.loginFooter {
    text-align: center;
}

.loginFooterDescription {
    color: var(--yellow);
    font-weight: 400;
    font-size: 1.4rem;
}

.loginFooterText {
    color: var(--white);
    font-weight: 500;
    font-size: 1.4rem;
}

@media only screen and (max-width: 950px) {
    .loginWrapperContainer {
        width: 40vw;
    }
}

@media only screen and (max-width: 850px) {
    .loginWrapperContainer {
        padding: 1rem;
    }
}

@media only screen and (max-width: 850px) {
    .loginWrapperContainer {
        width: 85%;
    }
}

@media only screen and (max-width: 650px) {
    .signInContainer {
        gap: 2rem;
        padding-bottom: 5rem;
    }

    .registerText {
        text-decoration: underline;
        color: var(--yellow);
    }
}
.widgetContainer {
    width: calc(100% - 2rem);
    height: calc(100% - 2rem);
    padding: 1rem;
    display: flex;
    flex-direction: column;
    box-shadow: var(--transparentBlackShadow);
    border-radius: 2rem;
}

.header {
    color: var(--blue);
    padding: .2rem;
    font-weight: bold;
}

.listContainer {
    height: calc(100% - 1rem);
    overflow: auto;
    scrollbar-width: none;
}

.listContainer::-webkit-scrollbar {
    display: none;
}

.listItem {
    display: flex;
    padding: .4rem;
    margin-top: .2rem;
}

.iconContainer {
    flex: 1;
}

.icon {
    padding: .2rem;
    color: var(--white);
    background: var(--indigo);
    border-radius: 50%;
    width: fit-content;
}

.iconElement {
    zoom: .8;
}

.infoContainer {
    flex: 6;
    min-width: 0;
    margin-left: .3rem;
}

.infoHeader {
    font-size: .8rem;
    font-weight: 600;
}

.infoDetails {
    color: var(--blue);
    font-size: .6rem;
}

.infoDetails p {
    margin: 0;
    padding: 0;
}

.buttonContainer {
    flex: 2;
}

@media only screen and (max-width: 480px) {
    .widgetContainer {
        width: 80vw;
        padding: 1rem 0.5rem 0.3rem;
        margin-bottom: 1rem;
    }

    .header {
        padding-left: 0.5rem;
    }

    .icon {
        width: 1.5rem;
        height: 1.5rem;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .buttonContainer {
        flex: 1;
    }
}

@media only screen and (min-width: 481px) and (max-width: 1024px) {
    .header {
        padding-left: 0.5rem;
    }

    .icon {
        width: 1.5rem;
        height: 1.5rem;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .buttonContainer,
    .iconContainer {
        flex: 0.5;
    }
}
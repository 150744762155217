.viewPasswordOuterContainer {
    background: var(--white);
    padding: 1.5rem;
    border-radius: 10px;
    height: 100%;
    font-family: Arial, sans-serif;
}

.passwordHeader {
    color: var(--blue);
    font-weight: bold;
    margin-bottom: 0.5em;
    font-size: 1rem;
}

.passwordDisplayContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.passwordText {
    color: var(--gray);
    font-size: 1.2em;
    text-align: center;
}
.passwordIconContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
}

.icon {
    color: var(--blue);
    cursor: pointer;
}

.helperText {
    font-size:.8rem;
    color: gray;
    margin-top: 1rem;
    margin-bottom: 1rem;
    text-align: center;
    display: block;
}

.passwordChoiceSelector {
    display: flex;
    justify-content: space-around;
    font-size: 0.8rem;
    margin-top: 0.5em;
    margin-bottom: 2em;
}

.passwordChoiceSelector > div {
    display: inherit;
    align-items: center;
}
.disabled {
    pointer-events: none;
    opacity: 0.5;
    cursor: not-allowed;
}

.passwordChoiceSelector svg {
    font-size: 1.5em;
}

.passwordChoiceSelector span {
    padding-left: 0.5rem;
    font-size: 1rem;
}

.passwordChoiceDialogButtonContainer {
    margin-top: 1.5rem;
    display: flex;
    justify-content: center;
    gap: 1rem;
}

.passwordChoiceGrayFont {
    color: var(--gray);
}

.passwordChoiceClickable {
    cursor: pointer;
}

.passwordChoiceBlue {
    color: var(--blue);
}

.passwordChoiceSelectedIcon {
    box-shadow: 0 0 10px var(--blue);
    border-radius: 50%;
}

.inputGroup {
    display: flex;
    align-items: center;
    gap: 8px;
    width: 95%;
}

.inputWithIcon {
    position: relative;
    display: flex;
    align-items: center;
    flex: 1;
}

.extensionInputBox {
    width: 100%;
    border: 0;
    border-radius: 10px;
    padding: 0.47rem;
    box-shadow: var(--transparentBlackShadow);
    color: var(--gray);
}

.extensionInputBox.errorInput {
    border: 1px solid var(--red);
}

.infoIcon {
    position: absolute;
    right: 10px;
    display: flex;
    align-items: center;
    color: var(--gray);
    cursor: pointer;
}

.infoIcon svg {
    font-size: 1.2rem;
}

.generateIcon {
    display: flex;
    align-items: center;
    color: var(--blue);
    cursor: pointer;
}

.generateIcon svg {
    font-size: 1.5rem;
}

.loaderContainer{
    margin-top: 1rem;
}

.confirmationContainer {
    display: flex;
    align-items: center;
    flex-direction: column;
    background: var(--white);
    border-radius: 10px;
}

.confirmationMessage {
    color: var(--gray);
    font-size: .95em;
    padding-top: 2em;
    text-align: center;
    line-height: 1.5;
}

.buttonContainer {
    margin: 1.5rem;
    display: flex;
    justify-content: center;
    gap: 1rem;
}

@media only screen and (max-width: 480px) {
    .passwordResetContainer {
        margin-top: 0.5rem;
    }
    .passwordChoiceSelector svg {
        font-size: 1.2em;
    }

    .passwordChoiceSelector span {
        font-size: 0.8rem;
    }
    .passwordHeader {
        font-size: .5rem;
    }
    .infoIcon {
        right: 0;
    }
}
.addVirtualNumberOuterContainer {
    font-family: Roboto,sans-serif;
    background: var(--white);
    padding: 1rem;
    border-radius: 10px;
    height: 100%;
}

.configurationSelector {
    display: flex;
    justify-content: space-around;
    margin-top: 1em;
    margin-bottom: 2em;
}

.configurationSelector > div {
    display: inherit;
}

.configurationSelector svg {
    font-size: 1.5em;
}

.configurationSelector span {
    padding-left: 0.5rem;
}

.addVirtualNumberContainer {
    display: flex;
    flex-direction: column;
}

.addVirtualNumberRow {
    display: flex;
    width: 100%;
}

.addVirtualNumberCell {
    flex: 1;
    display: flex;
    flex-direction: column;
}

.textLabel {
    color: var(--blue);
    font-weight: bold;
    margin-left: .2rem;
}

.roundControl {
    border: 0;
    box-shadow: var(--transparentBlackShadow);
    border-radius: 10px;
    padding: 0.47rem;
    width: 93%;
    margin-top: 0.5rem;
}

.roundControl::placeholder {
    font-size: .8rem;
}

.roundControl::-webkit-inner-spin-button,
.roundControl::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.roundControl span {
    margin: 0;
}

.ipSelector {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 90%;
    font-size: 0.8em;
    cursor: pointer;
}

.dropDownContainer{
    width: 90%;
}
.ipSelector svg {
    font-size: 1.3em;
}

.ipSelector > span {
    padding-left: 3%;
}

.addVirtualNumberDialogButtonContainer {
    margin-top: 1.5rem;
    display: flex;
    justify-content: center;
    gap: 1rem;
}

.errorMessageContainer {
    font-size: .7rem;
    color: var(--red);
    font-weight: bold;
    margin-top: 0.5rem;
}

.errorIcon {
    width: 1rem;
    margin-right: 6px;
}

.dotLoaderContainer {
    padding-top: 2em;
}

.confirmationContainer {
    display: flex;
    align-items: center;
    flex-direction: column;
}

.confirmationContainer > svg {
    color: var(--darkGreen);
    width: 2em;
    height: 2em;
}

.confirmationHeader {
    color: var(--blue);
    font-weight: bold;
}

.confirmationText {
    color: var(--gray);
    padding-top: 1em;
    padding-bottom: 1em;
    text-align: center;
}

.confirmationContainer > button {
    padding-left: 1.5em;
    padding-right: 1.5em;
}

.grayFont {
    color: var(--gray);
}

.clickable {
    cursor: pointer;
}

.blue {
    color: var(--blue);
}

.selectedIcon {
    box-shadow: 0 0 10px var(--blue);
    border-radius: 50%;
}

.combinedInputContainer {
    display: flex;
    width: 100%;
    border-radius: 10px;
}

.addIcon {
    cursor: pointer;
    color: var(--blue);
    margin-top: auto;
    margin-left: .5rem;
    margin-bottom: .3rem;
}
.addIcon svg {
    font-size: 1.2rem;
}
.vosAccountInput,
.extensionInput {
    border: 0;
    padding: 0.47rem;
    font-size: 0.8rem;
    background-color: transparent;
}

.vosAccountInput {
    color: var(--gray);
    pointer-events: none;
    width: 5rem;
}

.extensionInput {
    flex: 1;
    padding-left: 0.8rem;
}

.extensionInput::placeholder {
    font-size: 0.8rem;
}

.extensionInput::-webkit-inner-spin-button,
.extensionInput::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

@media only screen and (max-width: 480px) {
    .addVirtualNumberRow {
        flex-direction: column;
    }

    .addVirtualNumberCell {
        margin-bottom: 1rem;
    }

    .addVirtualNumberDialogButtonContainer{
        margin-top: 0;
    }

}
.dialogContent {
    padding: 20px;
}

.dialogInput {
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
}

.errorMessage {
    color: red;
    margin-bottom: 10px;
    font-size: 0.875rem;
}
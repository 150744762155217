.kycContainer {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    margin-top: 2rem;
    height: 20rem;
}

.kycContainer > h2,
.kycContainer > p {
    color: var(--coldPurple);
    margin: 0;
}

.iconsContainer {
    display: grid;
    grid-template-rows: repeat(3, 1fr);
    grid-template-columns: repeat(2, 1fr);
    gap: 2rem;
    margin: 1rem 1.5rem 1rem 0;
    text-align: center;
}

.detailsContainer {
    border: none;
    outline: none;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    width: 100%;
    border-radius: 10px;
    margin: 0.3rem;
    box-shadow: 3px 5px 8px 2px #72727252;
    cursor: pointer;
    padding: 0.3rem;
}

.identityProofContainer {
    position: relative;
    left: 60%;
}

.detailsContainer > div {
    color: var(--coldPurple);
    font-weight: 500;
    padding-left: 1rem;
}

.detailsContainer > img {
    right: 0.5rem;
    position: relative;
}

.error {
    border: 1px solid #e74c3c;
    box-shadow: 3px 5px 8px 2px #e93d3d3d;
}

@media only screen and (max-width: 480px) {
    .iconsContainer {
        grid-template-columns: repeat(1, 1fr);
        max-height: 30rem;
        overflow-y: auto;
        width: 16rem;
        margin: 1rem 0 0 1rem;
        padding-bottom: 1.5rem;
    }

    .detailsContainer {
        width: 80%;
        text-align: center;
    }
}
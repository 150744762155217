.widgetContainer {
    width: calc(100% - 2rem);
    height: calc(100% - 2rem);
    padding: 1rem;
    display: flex;
    flex-direction: column;
    box-shadow: var(--transparentBlackShadow);
    border-radius: 2rem;
}

.iconArea {
    flex: .5
}

.icon {
    color: var(--blue);
    height: 2rem;
    width: 2rem;
}

.bottomContainer {
    flex: 2.5;
    display: flex;
    flex-direction: column;
}

.centerAlign {
    flex: 1;
}

.dataArea {
    flex: 1.5;
    font-weight: bold;
    text-align: center;
    color: var(--darkBlue);
    font-size: 2.5vw;
    text-size-adjust: auto;
    -webkit-text-size-adjust: auto;
    min-height: 0;
    display: flex;
    align-items: flex-end;
    justify-content: center;
}

.labelArea {
    flex: 1;
    text-align: center;
    color: var(--blue);
    font-weight: 700;
}

@media only screen and (max-width: 480px) {
    .widgetContainer {
        width: 30vw;
        height: 30vw;
        border-radius: 20px;
        padding: 0.5rem 1rem;
    }

    .dataArea {
        font-size: 7vw!important;
    }

    .labelArea{
        font-size: 4vw!important;
    }

    .iconArea{
        flex: 0.2;
    }
    .bottomContainer{
        flex: 3;
    }
}

@media only screen and (min-width: 851px) and (max-width: 1024px) {
    .dataArea{
        font-size: 3vw!important;
    }

    .labelArea{
        font-size: 2vw!important;
    }

    .widgetContainer {
        padding: 0.5rem 1rem;
        width: 17vw;
        height: 17vw;
    }
}

@media only screen and (min-width: 481px) and (max-width: 850px) {
    .widgetContainer {
        padding: 0.3rem;
        border-radius: 20px;
        width: 19vw;
        height: 20vw;
    }

    .labelArea {
        font-size: 2.2vw !important;
    }

    .dataArea {
        font-size: 3.5vw !important;
    }
}
.waitingContainer {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.waitingContainer h3 {
    color: var(--blue);
    justify-self: flex-start;
}
.pwdVerificationContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
}

.pwdVerificationContent {
    display: flex;
    align-items: center;
    flex-direction: column;
    border-radius: 2rem;
    border: 0;
    box-shadow: var(--semiTransparentDarkShadow);
    background-color: rgb(253, 255, 255);
    padding-left: 5rem;
    padding-right: 5rem;
    padding-bottom: 2rem;
}

.imageContainer {
    flex: 2;
    overflow: hidden;
    padding: 1.2em;
}

.pwdVerificationImage {
    height: 15em;
    max-width: 100%;
}

.pwdVerificationText {
    position: relative;
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 900;
    font-size: 0.8rem;
    line-height: 1rem;
    text-align: center;
    color: var(--mediumDark);
}

.blueFont {
    color: var(--blue)
}

.redFont {
    color: var(--red);
}

.pwdVerificationForm {
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 900;
    font-size: 0.8rem;
    text-align: center;
    color: var(--mediumDark);
    padding-top: 1.5em;
}

.pwdVerificationForm > p {
    font-weight: 900;
    margin: 0;
}

.roundControl {
    border: 0;
    box-shadow: var(--transparentBlackShadow);
    border-radius: 19px;
    padding: .4rem;
    width: calc(70% - .8rem);
    margin-top: .3rem;
}

.errorMessageContainer {
    font-size: .7rem;
    color: var(--red);
    font-weight: normal;
    margin-top: 0.5rem;
}

.errorIcon {
    width: 1rem;
    margin-right: 6px;
}

.roundedButton {
    padding: 0.5em 1em;
    margin: 1.5em 0.7em 0.7em 0.7em;
    width: fit-content;
    display: inline-flex;
    align-items: center;
}

.messageContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 1.5em;
}

.messageContainer > p {
    font-weight: 600;
    margin: 0;
}

.messageText {
    color: var(--gray);
    font-size: 0.8em;
    padding-top: 1em;
    padding-bottom: 0.5em;
    text-align: center;
}

.messageContainer > button {
    padding-left: 1.5em;
    padding-right: 1.5em;
}

.dotLoader {
    padding-top: 1em;
}
.countrySelectorContainer {
    margin-top: .1rem;
}

.searchInputBox {
    width: 100%;
    height: 100%;
    border: 0;
    background: transparent;
    font-size: .8rem;
}

.searchInputBox::placeholder {
    font-size: .8rem;
}

.roundedContainer {
    border: 0;
    box-shadow: var(--transparentBlackShadow);
    border-radius: 10px;
    padding: .4rem;
    margin-top: .3rem;
}

.countrySelectorButton {
    display: flex;
    color: var(--gray);
    text-indent: 0.3em;
    width: 90%;
}

.countrySelectorButton:focus {
    outline: 0px solid transparent;
}

.countrySelectorDropdown {
    position: absolute;
    flex-direction: column;
    padding: 0.5em;
    width: max-content;
    background-color: var(--white);
}

.countrySelectorSearchBox {
    width: 95%;
    margin-bottom: 1em;
}

.countrySelectorOptionContainer {
    max-height: 6em;
    overflow-y: auto;
}

.countrySelectorOption:hover {
    box-shadow: var(--transparentBlackShadow);
}

.countrySelectorOption {
    padding: 0.3em;
    display: flex;
    align-items: center;
    cursor: pointer;
    margin-top: .3rem;
    font-size: .8rem;
}

.countrySelectorOption > input[type="checkbox"] {
    cursor: pointer;
}

.countrySelectorOption > span {
    padding-left: 0.3em;
}

.countrySelectorOption > svg {
    font-size: 1.2em;
}

.countrySelectorOption > input {
    margin-right: 0.6em;
}

.intFlagIconContainer {
    padding-left: 0;
    text-indent: 0;
}

.internationalFlagIcon {
    width: 1.2em;
}

.countrySelectorDropdownButtonContainer {
    display: flex;
    justify-content: center;
}

.countrySelectorDropdownButtonContainer > button {
    border-radius: 30px;
    border: 0;
    background: var(--blue);
    color: var(--white);
    padding: 0.5em 1em;
    margin: 0.7em;
    width: fit-content;
    cursor: pointer;
}

.selectedCountryCard {
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    flex-basis: fit-content;
    border: 0;
    box-shadow: var(--transparentBlackShadow);
    border-radius: 6px;
    padding: 0.2rem 0.3rem;
    width: calc(80% - 0.8rem);
    margin-left: 0.4rem;
    font-size: .6rem;
}

.clearIcon {
    padding-left: 0.9em;
    cursor: pointer;
}

.selectedCountryCard > svg {
    height: 1.2em;
    width: 1.2em;
}

.errorText {
    color: red;
    font-size: .7rem;
    font-weight: bold;
    margin-left: .2rem;
}
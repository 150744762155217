.cdrContainer{
    display: flex;
    height: 90%;
}

.calendarContainer {
    flex: .5;
    height: 90%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.dataContainer {
    flex: 1;
    height: 90%;
    margin-left: 4rem;
    border-radius: 20px;
    background: var(--white);
    box-shadow: var(--transparentBlackShadow);
    overflow: hidden;
}

.searchIcon {
    color: var(--blue);
    width: 1.5rem;
}

.searchContainer {
    height: 100%;
    background: var(--white);
    border-radius: 10px;
    padding: .5rem;
}

.searchFormRow {
    display: flex;
    flex: 1;
}

.searchFormCell {
    flex: 1;
    padding: .5rem;
}

.timepickerWrapper {
    display: flex;
    width: 80%;
}

.roundLabelBox {
    flex: 1;
    background: var(--blue);
    color: var(--white);
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: .8rem;
    z-index: 2;
}

.timePicker {
    flex: 2;
    margin-left: -5rem;
}

.timePicker > div {
    border-radius: 20px;
    box-shadow: var(--transparentBlackShadow);
    border: 0;
    padding-left: 7rem;
}

.timePicker > div > div {
    min-width: fit-content;
}

.textLabel {
    color: var(--blue);
    font-weight: bold;
    font-size: 1rem;
}

.roundInputBox {
    border: 0;
    box-shadow: var(--transparentBlackShadow);
    border-radius: 20px;
    padding: .4rem;
    width: calc(80% - .8rem);
    margin-top: .3rem;
}

.roundInputBox::-webkit-inner-spin-button,
.roundInputBox::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.searchPopupButton {
    margin-right: .5rem;
}

.buttonContainer {
    text-align: center;
    flex: 1;
    margin-top: .5rem;
    display:flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
}

.searchIconWrapper {
    cursor: pointer;
}

.cdrSearchDialogWrapper {
    height: 80vh;
}

.cdrSearchGridContainer {
    height: 90%;
    overflow: hidden;
}

.searchResultDialogButtonWrapper {
    padding-left: 1.5rem;
    display: flex;
}

.downloadIconWaiting {
    width: 2.5rem;
}

@media screen and (max-width: 720px) {
    .cdrContainer {
        flex-direction: column;
        height: fit-content;
    }

    .dataContainer {
        margin: 1.5rem;
        height: 20rem;
        overflow: visible;
    }
}

@media screen and (max-width: 480px) {
    .searchFormRow {
        flex-direction: column;
    }

    .timepickerWrapper{
        width: 100%;
    }

    .roundInputBox{
        width: 94%;
    }

    .cdrSearchDialogWrapper{
        height: 50vh;
    }
}

@media screen and (min-width: 480px) and (max-width: 720px) {
    .cdrSearchDialogWrapper {
        height: 60vh;
    }
}
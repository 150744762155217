.addIPOuterContainer {
    background: var(--white);
    padding: 0.5rem;
    border-radius: 10px;
    height: 100%;
}

.configurationSelector {
    display: flex;
    justify-content: space-around;
    font-size: 0.8em;

    margin-top: 0.5em;
    margin-bottom: 2em;
}

.configurationSelector > div {
    display: inherit;
}

.configurationSelector svg {
    font-size: 1.5em;
}

.configurationSelector span {
    padding-left: 0.5rem;
}

.addIPContainer {
    display: flex;
    flex-direction: column;
}

.addIPRow {
    display: flex;
    width: 100%;
}

.addIPCell {
    flex: 1;
    display: flex;
    flex-direction: column;
}

.callDestinationContainer {
    display: flex;
    flex-direction: column;
}

.textLabel {
    color: var(--blue);
    font-weight: bold;
    font-size: .8rem;
    margin-left: .2rem;
}

.roundControl {
    border: 0;
    box-shadow: var(--transparentBlackShadow);
    border-radius: 10px;
    padding: 0.47rem;
    width: 93%;
    margin-top: 0.3rem;
}

.roundControl::placeholder {
    font-size: .8rem;
}

.roundControl::-webkit-inner-spin-button,
.roundControl::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.roundControl span {
    margin: 0;
}

.ipSelector {
    display: flex;
    justify-content: space-between;
    align-items: center;

    width: 90%;
    font-size: 0.8em;

    cursor: pointer;
}

.ipSelector svg {
    font-size: 1.3em;
}

.ipSelector > span {
    padding-left: 3%;
}

.flagSelector > ul {
    top: -90%;
    border: 0;
    box-shadow: var(--transparentBlackShadow);
    border-radius: 10px;
}

.selectedCountriesContainer {
    display: flex;
    flex-wrap: wrap;
    border: 0;
    box-shadow: var(--transparentBlackShadow);
    border-radius: 10px;
    padding: .4rem;
    width: calc(80% - .8rem);
    margin-top: .3rem;
    font-size: 1em;

    overflow-y: auto;
    max-height: 6em;
}

.intFlagIconContainer {
    padding-left: 0;
}

.internationalFlagIcon {
    width: 1.2em;
}

.addIPDialogButton {
    padding: 0.5em 1em;
    margin: 0.7em;
    display: inline-flex;
    align-items: center;
}

.addIPDialogButtonContainer {
    margin-top: 1.5rem;
    display: flex;
    justify-content: center;
    gap: 1rem;
}

.flagSelector ul li:nth-child(7) svg {
    visibility: hidden;
}

.errorMessageContainer {
    font-size: .7rem;
    color: var(--red);
    font-weight: bold;
    margin-top: 0.5rem;
}

.errorIcon {
    width: 1rem;
    margin-right: 6px;
}

.addIPErrorContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 1.5em;
}

.dotLoaderContainer {
    padding-top: 2em;
}

.confirmationContainer {
    display: flex;
    align-items: center;
    flex-direction: column;
}

.confirmationContainer > svg {
    color: var(--darkGreen);
    width: 2em;
    height: 2em;
}

.confirmationHeader {
    color: var(--blue);
    font-weight: bold;
}

.confirmationText {
    color: var(--gray);
    font-size: 0.8em;
    padding-top: 1em;
    padding-bottom: 0.5em;
    text-align: center;
}

.confirmationContainer > button {
    padding-left: 1.5em;
    padding-right: 1.5em;
}

.grayFont {
    color: var(--gray);
}

.clickable {
    cursor: pointer;
}

.blue {
    color: var(--blue);
}

.selectedIcon {
    box-shadow: 0 0 10px var(--blue);
    border-radius: 50%;
}

@media only screen and (max-width: 480px) {
    .addIPRow {
        flex-direction: column;
    }

    .addIPCell {
        margin-bottom: 1rem;
    }

    .addIPDialogButtonContainer{
        margin-top: 0;
    }

    .roundControl {
        width: 90%;
    }
}
.numberWidgetContainer {
    width: calc(100% - 2rem);
    padding: 1rem;
    display: flex;
    box-shadow: var(--transparentBlackShadow);
    border-radius: 2rem;
    height: auto;
    justify-content: space-between;
    min-height: 6.5rem;
}

.numberWidgetContainerRight {
    display: flex;
    flex-direction: column;

}

.labelArea {
    flex: 1;
    color: var(--blue);
    font-weight: 700;
    text-align: right;
    padding-top: .3rem;
}

.dataArea {
    flex: 1;
    font-weight: bold;
    color: var(--lightPurple);
    text-align: right;
}

.icon {
    color: var(--blue);
    font-size: 3rem;
    height: 4rem;
    width: 4rem;
}

.iconArea {
    flex: .5
}

@media only screen and (max-width: 480px) {
    .numberWidgetContainer {
        width: 30vw;
        height: 30vw;
        border-radius: 20px;
        display: flex;
        flex-direction: column;
        padding: 0.5rem 1rem 1rem;
        justify-content:flex-start;
    }

    .dataArea {
        font-size: 7vw !important;
        text-align: center;
        flex: 1.5;
        display: flex;
        align-items:flex-end;
        justify-content: center;
    }

    .labelArea {
        font-size: 4vw !important;
        text-align: center;
        flex: 1;
    }

    .iconArea {
        flex: 0.2;
    }

    .numberWidgetContainerRight {
        flex: 2;
        display: flex;
    }

    .icon {
        height: 2.5rem;
        width: 2.5rem;
    }

}

@media only screen and (min-width: 851px) and (max-width: 1024px) {
    .numberWidgetContainer {
        border-radius: 20px;
        padding-left: 0.3rem;
        padding-top: 0.3rem;
    }

    .icon{
        width: 2.5rem;
        height: 2.5rem;
    }

    .labelArea {
        margin-top: 0.5rem;
    }
}

@media only screen and (min-width: 481px) and (max-width: 850px) {
    .numberWidgetContainer{
        padding-left: 0.5rem;
        padding-top: 0.5rem;
        padding-bottom: 0.3rem;
        border-radius: 20px;
    }

    .icon {
        width: 3rem;
        height: 3rem;
    }
}
.container {
    height: 90%;
    border-radius: 20px;
    background: var(--white);
    box-shadow: var(--semiTransparentDarkShadow);
}

.tableContainer {
    height: 99%;
}

.statusBadge {
    color: white;
    padding: 0.2rem 0.4rem;
    font-weight: bold;
    border-radius: 5px;
    display: inline-block;
    text-align: center;
}

.completed {
    background-color: #A7F3D0;
    color: #166534;
}

.initiated {
    background-color: #FEE2B3;
    color: #B45309;
}

.cancelled {
    background-color: #FECACA;
    color: #9B1C31;
}

.icon {
    color: var(--blue);
    cursor: pointer;
}

.orderDetailsContainer {
    background: white;
    border-radius: 10px;
    padding: 1rem 2rem;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    font-family: sans-serif;
}

.orderDetailsHeader {
    color: var(--blue);
    font-weight: 500;
    text-align: center;
    text-decoration: underline;
}

.orderDetailsContent {
    display: flex;
    flex-direction: column;
    gap: .5rem;
    margin-bottom: 1rem;
}

.orderDetailsRow {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 2rem;
    padding: 0.5rem 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.orderDetailsLabel {
    color: #666;
    font-size: 0.9rem;
}

.orderDetailsValue {
    color: var(--blue);
    font-weight: 500;
    font-size: 0.9rem;
}

.orderItemsContainer {
    margin-top: 2rem;
    padding: 1rem;
    background: #f8f9fa;
    border-radius: 8px;
}

.orderDetailsData{
    max-height: 15vh;
    overflow-y: auto;
}

.orderItemsContainer h4 {
    color: var(--blue);
    text-align: center;
    font-weight: 500;
    margin-top: 0;
    margin-bottom:1rem;
}

.orderItemDetails {
    background: white;
    padding: 1rem;
    border-radius: 8px;
    margin-bottom: 1rem;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.orderDetailsTotalAmount {
    font-size: 1rem;
    font-weight: 500;
    color: var(--red);
}

.orderItemTotalPrice {
    font-size: 1rem;
    font-weight: 500;
    color: var(--green);
}

.dialogButtonContainer {
    display: flex;
    justify-content: center;
    padding: 1rem;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
}

@media screen and (max-width: 480px) {

    .tableContainer span {
        display: block;
        padding: 0.3rem;
        font-size: 0.9em;
    }

    .statusBadge {
        font-size: 0.9em;
        width: fit-content;
        margin: .5em 0;
    }

    .icon {
        font-size: 1.2rem;
    }

    .orderDetailsContainer {
        padding: 0.8rem;
    }

    .orderDetailsHeader {
        display: none;
    }

    .orderDetailsData{
        max-height: 15vh;
        overflow-y: auto;
    }

    .orderDetailsRow {
        font-size: .9em;
    }

    .orderDetailsLabel, .orderDetailsValue {
        font-size: 0.75rem;
    }

    .orderItemsContainer {
        padding: 0.5rem;
        margin-top: 0;
    }

    .orderItemsContainer h4 {
        margin-top: .3rem;
    }

    .orderItemDetails {
        padding: 0.5rem;
    }
}
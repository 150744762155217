.cancelIcon {
    z-index: 25;
    position: absolute;
    top: 10px;
    right: 10px;
    color: var(--coldPurple);
    cursor: pointer;
}

.arrowBackIcon {
    z-index: 25;
    position: absolute;
    top: 10px;
    left: 10px;
    color: var(--coldPurple);
    cursor: pointer;
}

.statusBarWrapperContainer {
    width: 90%;
    height: 5px;
    background-color: #E0E0E0;
    border-radius: 10px;
    top: 20px;
    margin: 1.5rem 1rem;
    position: relative;
    --percentage: 0;
}

.filledStatusBar {
    padding-left: 1rem;
    height: 100%;
    background: linear-gradient(#FF90F4, #49318D);
    border-radius: 10px;
    transition: width 0.3s ease-in-out;
    position: relative;
}

.iconContainer {
    display: flex;
    align-items: center;
    position: absolute;
    top: 0;
    bottom: 0;
    width: 20px;
    transition: left 0.3s ease-in-out;
}

.circleIcon {
    margin-top: 0.4rem;
    margin-left: -1rem;
}

.iconLeft,
.iconRight {
    position: absolute;
    top: 50%;
    height: 1.5rem;
    width: 1.5rem;
    transform: translateY(-50%);
    cursor: pointer;
    z-index: 3;
}

.iconLeft {
    left: 5px;
}

.iconRight {
    right: 5px;
}

.dynamicWidth {
    width: calc(var(--percentage) * 1%);
}

.dynamicLeft {
    left: calc(var(--percentage) * 1%);
}

.kycButtonContainer {
    padding: 1rem;
    display: flex;
    justify-content: center;
}

.kycButton {
    background-color: var(--coldPurple);
    height: 2.5rem;
    width: 2.5rem;
    outline: none;
    border: 2px solid white;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    box-shadow: 0 5px 6px 1px #7C6FB3;
}

.arrowRightIcon {
    color: var(--white);
}

.arrowBackIconVisible {
    display: block;
}

.arrowBackIconHidden {
    display: none;
}

.error {
    color: red;
    font-size: 0.9rem;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 0.5rem;
}

.error > img {
    width: 1rem;
    height: 1rem;
    padding-right: 0.3rem;
}

.footer {
    background-color: var(--coldPurple);
    padding: 0.5rem;
    display: flex;
    justify-content: center;
}

.navigateButton {
    background-color: var(--coldPurple);
    height: 2.5rem;
    width: 2.5rem;
    outline: none;
    border: 2px solid white;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    box-shadow: 0 5px 6px 1px #7C6FB3;
    margin-right: 1rem;
}

.rotate180deg {
    transform: rotate(180deg);
}



.consentContainer {
    display: flex;
    justify-content: center;
    align-items: center;
}

.consentCheckbox {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    background-color: #fafafa;
    border-radius: 2px;
    border: 2px solid var(--coldPurple);
    width: 15px;
    height: 15px;
    display: inline-block;
    position: relative;
    cursor: pointer;
    margin-right: 0.5rem;
}

.consentCheckbox:checked {
    box-shadow: 0 0 4px 3px var(--coldPurple);
    background-color: var(--coldPurple);
    border: 2px solid var(--white);
}

.consentText {
    color: var(--gray);
}

.kycInfoLoader {
    position: absolute;
    top: 50%;
    left: 43%;
    z-index: 20;
}

.overlay {
    position: absolute;
    top: 0;
    left: 0;
    background: var(--white);
    width: 100%;
    height: 100%;
    opacity: 0.7;
    z-index: 10;
}

@media only screen and (max-width: 480px) {
    .consentContainer {
        margin: 0.5rem 0.5rem 0;
        align-items: flex-start;
    }
}

.countryCodeDropdownMenuContainer {
    box-shadow: var(--transparentBlackShadow);
    max-height: 10em;
    width: 15em;
    margin-left: 0.5rem;

    position: absolute;
    z-index: 1;
    border-radius: 10px;
}

.countryCodeDropdownMenu {
    width: inherit;
    max-height: 8em;
    overflow-y: auto;
    overflow-x: hidden;
    background-color: var(--blue);
    margin-left: 0;
    border-radius: 5px;
}

.countryCodeDropdownOption {
    display: flex;
}

.countryCodeDropdownOption > div {
    padding-right: 1em;
}

.countryCodeDropdownOption:hover {
    box-shadow: var(--transparentBlackShadow);
}

.countryCodeSearch {
    position: sticky;
    top: 0;
    z-index: 2;
    padding: 6px;
    box-shadow: var(--transparentBlackShadow);
    outline: none;
    align-self: stretch;

    margin-top: 4px;
    border-radius: 10px;

    border: 0;
    border-bottom: 2px solid var(--lightGray);

    width: 95%;
    font-weight: normal;
    font-size: 0.9em;
}

.countryCodeDropdownOption {
    padding: 0.5rem;
    font-family: 'Roboto', sans-serif;
    font-weight: normal;
    font-size: 0.9em;
    color: var(--white);
    cursor: pointer;
    transition: background-color 0.3s ease;
}
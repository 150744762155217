.wrapperContainer {
    position: relative;
    display: flex;
    padding: 0 30px;
    height: 28.3rem;
}

.leftContainerText {
    padding-top: 1.1rem;
    color: var(--gray);
    font-size: 0.9rem;
}

.docContainer {
    display: flex;
    justify-content: flex-start;
}

.activeDoc1,
.activeDoc2 {
    border: 1px solid var(--coldPurple);
    background-color: var(--coldPurple);
    color: var(--white);
    padding: 0.3rem;
    cursor: pointer;
    font-size: 0.8rem;
}

.deactiveDoc1,
.deactiveDoc2 {
    border: 1px solid var(--coldPurple);
    color: var(--coldPurple);
    background-color: var(--white);
    padding: 0.3rem;
    cursor: pointer;
    font-size: 0.8rem;
}

.leftContainer {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    flex: 2;
    padding-left: 1rem;
}

.rightContainer {
    display: flex;
    align-items: stretch;
    flex-direction: column;
    flex: 3;
    border-left: 1px solid var(--coldPurple);
    margin: 3.5rem 1rem;
    padding-left: 1rem;
    justify-content: center;
}

.uploadHelpContainer {
    display: flex;
    align-items: center;

    margin: 0.2em 0 0.5em 0;
}

.uploadText {
    color: var(--gray);
    font-size: 0.5rem;

}

.uploadContainer  svg {
    color: var(--blue);
    font-size: 01em;
    margin-left: 0.3em;
}

.uploadContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.uploadButton {
    margin-top: 1rem;
    padding: 0.3rem 2rem;
    color: var(--white);
    background-color: var(--coldPurple);
    border: none;
    border-radius: 20px;
    margin-bottom: 3rem;
    cursor: pointer;
    box-shadow: 1px 3px 5px 0 #727272;
}

.inputBoxContainer {
    margin-bottom: 0.5rem;
}

.gridContainer {
    display: inline-flex;
    gap: 1rem;
}

.rightContainer > label {
    padding-bottom: 0.5rem;
    color: var(--gray);
    font-size: 0.8rem;
}

.errorBorder {
    border: 1px solid red;
}

.descriptionIcon {
    height: 7rem;
    width: 5rem;
    color: #DFDFDF;
    border-radius: 20px;
    margin-top: 1rem;
    background-color: #F1F1F1;
    padding: 1.5rem;
}

.error {
    color: red;
    margin-bottom: 10px;
    font-size: 0.8rem;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-left: 0.5rem;
}

.error > img {
    width: 1rem;
    height: 1rem;
    padding-right: 0.3rem;
}

.uploadedFileName{
    color: var(--gray);
    font-size: 0.7rem;
    padding: 0;
    margin: 0 0 0.5rem;
}

.docFileError{
    color: red;
}

@media only screen and (max-width: 480px) {
    .wrapperContainer {
        flex-direction: column;
        max-height: 28rem;
        overflow-y: auto;
    }

    .leftContainer {
        flex: 0.5;
        align-items: center;
        padding-bottom: 2rem;
        border-bottom: 1px solid var(--coldPurple);
    }

    .rightContainer {
        flex: 1;
        border-left: none;
        margin-top: 1rem;
    }

    .gridContainer {
        flex-direction: column;
    }
}
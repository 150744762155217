.overlay {
    position: fixed;
    top: 0;
    left: 0;
    background: var(--white);
    width: 100%;
    height: 100%;
    opacity: .7;
    z-index: 10;
}

.dialog {
    position: absolute;
    width: 20rem;
    min-height: 8rem;
    left: calc(50% - 10rem);
    border-radius: 10px;
    background: var(--blue);
    z-index: 11;
    box-shadow: var(--semiDarkShadow);
    overflow: hidden;
}

.smallMedium {
    width: 30rem;
    left: calc(50% - 15rem);
}

.medium {
    width: 40rem;
    left: calc(50% - 20rem);
}

.large {
    width: 50vw;
    left: calc(50% - 25vw);
    top: calc(50% - 20vh);
    max-height: 60vh;
}

.extraLarge {
    width: 80vw;
    left: 10vw;
    top: 10vh;
    max-height: 80vh;
}

.headerContainer {
    text-align: center;
    padding: .5rem;
    color: var(--white);
    font-weight: bold;
}

.textContainer {
    position: relative;
    background: var(--white);
    border-radius: 10px;
    padding: 1rem;
    text-align: center;
    color: var(--gray);
    font-family: Roboto, serif;
}

@media only screen and (max-width: 480px) {
    .smallMedium {
        width: 18rem;
        left: calc(50% - 9rem);
    }

    .medium {
           width: 20rem;
           left: calc(50% - 10rem);
       }

    .extraLarge {
        width: 25rem;
        left: calc(50% - 12.5rem);
    }
}

@media only screen and (min-width: 480px) and (max-width: 720px) {
    .medium {
        width: 30rem;
        left: calc(50% - 15rem);
    }
}
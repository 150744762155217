.menuItemCardsContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    padding-top: .5rem;
    padding-bottom: 1.5rem;
    height: 15vh;
}

.cardInnerDiv{
    position: relative;
    margin-top: 0.3rem;
    margin-left: 0.4rem;
    width: 100%;
    height: 100%;
    border-radius: 20px;
    background:var(--purple);
    box-shadow: var(--lightPurpleShadow);
    cursor: pointer;
}

.cardOuterDiv {
    position: relative;
    background: var(--periwinkleGrey);
    box-shadow: var(--lightPurpleShadow);
    cursor: pointer;
    border-radius: 20px;
    overflow: visible;
    width: 20vh;
}

.cardOuterDiv:not(:first-of-type) {
    margin-left: 2rem;
}

.cardContentDiv {
    width: 100%;
    height: 100%;
    padding-top: 1rem;
    padding-left: 1rem;
    overflow-wrap: break-word;
}

.titleText {
    position: relative;
    bottom: 1%;
    color: var(--white);
    font-family: 'Roboto', sans-serif;
    width: 85%;
    height: calc(100% - 3rem);
    display: flex;
    align-items: flex-end;
}

.titleText > span {
    padding-right: 1em;
}

.circle {
    position: relative;
    margin-left: calc(100% - 3rem);
    width: 1.2rem;
    height: 1.2rem;
    border: 2px solid white;
    border-radius: 50%;
}

@media only screen and (max-width: 480px) {
    .menuItemCardsContainer {
        height: fit-content;
        padding: 0;
        display: flex;
        justify-content: center;
    }

    .cardOuterDiv {
        width: calc(50% - 2rem);
        height: 8rem;
        margin-bottom: 1rem;
        margin-right: 1.5rem;
    }

    .cardOuterDiv:not(:first-of-type) {
        margin-left: 0;
    }

    .titleText {
        font-size: 5vw!important;
    }
}

@media only screen and (min-width: 720px) and (max-width: 1024px) {
    .cardOuterDiv:not(:first-of-type) {
        margin-left: 0;
    }

    .cardOuterDiv {
        margin-right: 1rem;
    }

    .titleText {
        width: 80% !important;
    }

    .cardInnerDiv {
        margin-left: 0;
    }

    .menuItemCardsContainer {
        display: flex;
        justify-content: center;
    }
}

@media only screen and (min-width: 480px) and (max-width: 740px) {
    .menuItemCardsContainer {
        height: fit-content;
        padding: 0 4.5rem;
        display: flex;
        justify-content: center;
    }

    .cardOuterDiv {
        width: calc(50% - 2rem);
        height: 8rem;
        margin-bottom: 1rem;
        margin-right: 1.5rem;
    }

    .cardOuterDiv:not(:first-of-type) {
        margin-left: 0;
    }

    .titleText {
        font-size: 4vw !important;
    }
}
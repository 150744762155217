.settingsOuterContainer {
    background: var(--white);
    padding: 1.5rem;
    border-radius: 10px;
    height: 100%;
    font-family: Arial, sans-serif;
}

.settingSection {
    margin-bottom: 2em;
}

.settingTitle {
    color: var(--blue);
    font-weight: bold;
    margin-bottom: 0.5em;
    font-size: 1rem;
}

.settingOptions {
    display: flex;
    justify-content: space-around;
    font-size: 0.8rem;
    margin-top: 0.5em;
}

.settingOptions > div {
    display: inherit;
    align-items: center;
}

.optionClickable {
    cursor: pointer;
    display: flex;
    align-items: center;
}

.optionClickable.disabled {
    pointer-events: none;
    opacity: 0.5;
    cursor: not-allowed;
}

.settingOptions svg {
    font-size: 1.5em;
}

.settingOptions span {
    padding-left: 0.5rem;
    font-size: 1rem;
}

.dialogButtonContainer {
    margin-top: 1.5rem;
    display: flex;
    justify-content: center;
    gap: 1rem;
}

.grayFont {
    color: var(--gray);
}

.selectedIcon {
    box-shadow: 0 0 10px var(--blue);
    border-radius: 50%;
}

.blue {
    color: var(--blue);
}

.loaderContainer {
    margin-top: 1rem;
    display: flex;
    justify-content: center;
}

.confirmationContainer {
    display: flex;
    align-items: center;
    flex-direction: column;
    background: var(--white);
    border-radius: 10px;
}

.confirmationMessage {
    color: var(--gray);
    font-size: .95em;
    padding-top: 2em;
    text-align: center;
    line-height: 1.5;
}

.buttonContainer {
    margin: 1.5rem;
    display: flex;
    justify-content: center;
    gap: 1rem;
}

@media only screen and (max-width: 480px) {
    .settingOptions svg {
        font-size: 1.2em;
    }

    .settingOptions span {
        font-size: 0.8rem;
    }

    .settingTitle {
        font-size: .5rem;
    }
}
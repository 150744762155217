.widgetContainer {
  width: calc(100% - 2rem);
  height: calc(100% - 2rem);
  padding: 1rem;
  display: flex;
  flex-direction: column;
  box-shadow: var(--transparentBlackShadow);
  border-radius: 2rem;
}

.header {
  margin-bottom: .5rem;
  color: var(--indigo);
  font-weight: 500;
  text-align: center;
}

.overlayTextContainer {
  width: 100%;
  height: 90%;
}

.text {
  font-size: 0.55rem;
  display: flex;
  color: var(--gray);
  justify-content: center;
  width: 100%;
}


.copyright {
    position: fixed;
    bottom: 0;
    left: 42vw;
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 0.8vw;
    letter-spacing: 0.15px;
    color: var(--semiTransparentDarkBlue);
    background-color: var(--titenWhite);
}

.clickable{
    color: var(--blue);
    cursor: pointer;
    margin-left: 5px;
}

.clickable:hover {
    text-decoration: underline;
}

@media only screen and (max-width: 769px) {
    .copyright {
        display: none;
    }
}
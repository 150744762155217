.popup {
  width: 13.5rem;
  height: fit-content;
  border-radius: 40px;
  background: var(--blue);
  box-shadow: var(--dullLavenderShadow);
  display: flex;
  flex-direction: column;
  position: absolute;
  right: 2rem;
  z-index: 999;
}

.nameInitialContainer {
  background: var(--purplishPink);
  border: 5px solid white;
  border-radius: 50%;
  margin-top: 8%;
  margin-bottom: 2rem;
  color: var(--white);
  box-shadow: var(--semiTransparentBlackShadow);
  position: relative;
  width: 5rem;
  height: 5rem;
}

.nameInitialContainer h2 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin: 0;
  font-size: 3rem;
}

.activeSymbol {
  position: absolute;
  margin-top: 3%;
  margin-left: 88%;
  background: var(--green);
  border-radius: 50%;
  width: 1.5vh;
  height: 1.5vh;
}

.userDetailContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 1rem;
  flex: 1;
}

.popUpName {
  font-size: 1rem;
  font-weight: 600;
  color: var(--blue);
}

.popUpId {
  color: var(--gray);
  font-size: 0.8rem;
  font-weight: 500;
}

.popUpAddress {
  font-size: 0.8rem;
  font-weight: 600;
  color: var(--blue);
}

.innerBox {
  border-radius: 40px;
  background: var(--titenWhite);
  box-shadow: var(--darkShadeShadow);
  padding-bottom: 1rem;
  text-align: center;
  flex: 4;

  display: flex;
  flex-direction: column;
  align-items: center;
}

.logOutButtonContainer {
  padding: .7rem;
  text-align: center;
  flex: 1;
}

@media only screen and (max-width: 1024px) {
  .popup {
    top: 5rem;
    left: 1rem;
    z-index: 11;
  }
}

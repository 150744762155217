.redirectionDetailsContainer {
    font-family: Roboto, sans-serif;
    display: flex;
    flex-direction: column;
    padding: 0 2rem 1rem;
    background-color: var(--white);
    border-radius: 10px;
}

.redirectionDetailsHeader {
    color: var(--blue);
    font-weight: 500;
    text-align: center;
}

.redirectionDetailsContent {
    display: flex;
    flex-direction: column;
    gap: 1.2rem;
}

.redirectionDetailsTable {
    width: 100%;
    border-collapse: collapse;
}

.tableHeader {
    display: flex;
    font-weight: bold;
    color: var(--gray);
    border-bottom: 2px solid rgba(0, 0, 0, 0.2);
}

.redirectionDetailsRow {
    display: flex;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.tableHeaderLabel,
.redirectionDetailsValue {
    flex: 1;
    padding: 0.5rem 1rem;
    text-align: left;
}
.redirectionDetails{
    max-height: 20rem;
    overflow-y: auto;
    padding-right: 5px;
}
.redirectionDetailsValue {
    color: var(--blue);
    font-weight: 500;
    font-size: 0.9rem;
}

.noRedirectionMessage {
    color: var(--gray);
    text-align: center;
}

.buttonContainer {
    display: flex;
    justify-content: center;
    margin-top: 1.5rem;
}

@media (max-width: 600px) {
    .redirectionDetailsHeader {
        font-size: 1rem;
    }

    .redirectionDetailsValue {
        font-size: 0.8rem;
    }

    .noRedirectionMessage {
        font-size: 0.9rem;
    }

    .tableHeaderLabel, .redirectionDetailsValue {
        padding: 0.4rem 0.8rem;
    }
}

@media (max-width: 480px) {
    .redirectionDetailsHeader {
        font-size: 1rem;
    }

    .redirectionDetailsValue {
        font-size: 0.5rem;
    }

    .noRedirectionMessage {
        font-size: 0.9rem;
    }

    .tableHeaderLabel, .redirectionDetailsValue {
        padding: 0.4rem 0.8rem;
        font-size: .5rem;
    }

    .redirectionDetailsContainer {
        padding: 0 1rem 1rem;
    }

    .buttonContainer {
        margin-top: 1rem;
    }
}
.font {
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 500;
    letter-spacing: 0.009rem;
    color: var(--gray);
}

.purchaseNumberContainer {
    /*display: flex;*/
    /*flex-direction: column;*/
    /*justify-content: center;*/
    /*align-items: center;*/
    /*width: calc(100% - 6.25vw);*/
    height: 90%;
    /*overflow: hidden;*/
}

.purchaseNumberLookupContainer {
    background-color: var(--white);
    box-shadow: var(--semiTransparentDarkShadow);

    padding: 0.5em;
    margin: 0 6em;
    border-radius: 20px;
}

.purchaseNumberLookupFormContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.countryCodeDropdownOption {
    display: flex;
}

.countryCodeDropdownOption > div {
    padding-right: 1em;
}

.countryCodeDropdownOption:hover {
    box-shadow: var(--transparentBlackShadow);
}

.purchaseNumberInputContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;

    padding: 0.5em 0;
    flex-wrap: wrap;
}

.purchaseNumberInput {
    display: flex;
    flex-direction: row;

    width: 10em;
    padding: 0 2em;
}

.purchaseNumberInput > label {
    color: var(--blue);
    font-size: 0.9em;
    padding-bottom: 0.5em;
    padding-right: 0.5em;
}

.requiredFieldIndicator {
    color: var(--red);
    font-size: 0.9em;
    padding-left: 0.2em;
}

.purchaseNumberInput svg {
    font-size: 1.2em;
    color: var(--lightGray);
    cursor: pointer;
}

.purchaseNumberInputField {
    width: 100%;
}

.purchaseNumberDropdownInput {
    display: flex;
    border-bottom: 2px solid var(--lightGray);
    justify-content: space-between;
    cursor: pointer;
}

.purchaseNumberDropdownInput > span {
    padding-left: 3%;
    font-weight: normal;
}

.statusBox {
    width: 10rem;
    z-index: 1;
    position: absolute;
    background-color: var(--white);
    box-shadow: var(--transparentBlackShadow);
    border-radius: 20px;

    padding: 0.3em;
    margin-top: 0.2em;
}

.notFoundMessageBox {
    display: flex;
    justify-content: center;
}

.enabled {
    pointer-events: auto;
}

.disabled {
    opacity: 0.6;
    pointer-events: none;
    cursor: not-allowed;
}

.dialCodeContainer {
    height: 1.2em;
    border-bottom: 2px solid var(--lightGray);
    display: flex;
    justify-content: space-between;
    cursor: pointer;
}

.selectedDialCode{
    display: flex;
    text-align: center;

    padding-left: 3%;
}

.selectedDialCode > span {
    padding-left: 0.5em;
    font-weight: normal;
}

.inlineBtn {
    display: flex;
    align-items: center;

    padding-left: 3em;
}

.purchaseNumberSelectionArea {
    display: flex;
    margin-top: 2em;
}

.purchaseNumberLookupResultContainer {
    display: flex;
    margin-top: 1.5em;
    height: 80%;
}

.purchaseNumberSelector {
    flex: 1 1;
    background-color: var(--white);
    box-shadow: var(--semiTransparentDarkShadow);
    border-radius: 20px;
}

.purchaseNumberSelectorGrid {
    height: 80%;
}

.selectorNumberCol {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.selectorNumberCol > div > svg {
    margin-right: 0.5em;
    font-size: 1.2vw;
}

.purchaseNumberSelectorFooter {
    height: 17%;

    display: flex;
    justify-content: space-around;
    align-items: center;

    padding-top: 3%;
}

.abbreviation {
    font-size: 0.7em;
}

.purchaseNumberCart {
    flex: 1 1;
    align-items: center;
    flex-direction: column;
    background-color: var(--white);
    box-shadow: var(--semiTransparentDarkShadow);
    border-radius: 20px;
    margin-left: 1em
}

.cancelIcon {
    color: var(--gray);
    margin-left: 5px;
    cursor: pointer;
    font-size: 18px;
}

.cancelIcon:hover {
    color: var(--red);
}
.cartIconContainer {
    height: 12%;
    text-align: center;
}

.cartIconContainer > svg {
    font-size: 2em;
    padding-top: 0.1em;
    color: var(--blue);
}

.cartGrid {
    height: 60%;
}

.cartAmountContainer {
    height: 13%;
    display: flex;
    padding-top: 0.5em;
}

.cartAmountContainer > div:nth-of-type(1) {
    flex: 1;
}

.cartAmountContainer> div:nth-of-type(2) {
    flex: 1.8;
}

.cartAmountContainer > div:nth-of-type(3) {
    flex: 1;
}

.cartButtonContainer {
    text-align: right;
    padding-right: 2em;
}

.cartAmountTotal {
    display: flex;
    justify-content: space-around;

    padding-top: 0.3em;
}

.bottomBorder {
    border-bottom: 2px solid var(--lightGray);
}

.rowHeader svg {
    font-size: 1.2vw;
}

.rowHeaderNumber {
    display: flex;
    justify-content: space-around;
}

.rowHeaderNumber > svg {
    padding-right: 1.8em;
}

.capabilityIconsContainer > svg:not(:first-child)  {
    padding-left: 0.5em;
}

.iconContainer svg{
    font-size: 1.2vw;
}

.tooltipIconsContainer {
    display: flex;
}

.tooltipIconsContainer svg {
    font-size: 0.75rem;
    padding-left: 0.3em;
}

.tooltipIconsContainer > div {
    display: flex;
    align-items: center;
}

.capabilityIconsDataContainer {
    padding-left: 5px;
}

.selectorIcon {
    font-size: 1.2vw;
}

.blue {
    color: var(--blue);
}

.selectedIcon {
    box-shadow: 0 0 10px var(--blue);
    border-radius: 50%;
}

.lightGray {
    color: var(--lightGray);
}

.boldFont {
    font-weight: bold;
}

.cartItemActionContainer > svg {
    padding-left: 0.5em;
}

.clickable {
    cursor: pointer;
}

.rightAlign {
    width: 100%;
    text-align: right;
}

.offerMessageContainer {
    margin-top: 0.5em;
    background-color: var(--lightGreen);
    color: var(--darkBlackishGreen);
    font-size: small;
    padding-right: 5em;
    text-align: right;
}

@media only screen and (max-width: 510px) {
    .purchaseNumberInputContainer {
        flex-direction: column;
    }

    .purchaseNumberInput {
        width: 70%;
    }

    .selectorNumberCol > div > svg {
        font-size: 2vw;
    }

    .size svg {
        font-size: 4vw;
    }
}

@media only screen and (max-width: 780px) {
    .purchaseNumberLookupContainer {
        margin: 0 1em;
    }

    .purchaseNumberLookupResultContainer {
        flex-direction: column;
    }

    .rowHeader svg {
        font-size: 3vw;
    }

    .purchaseNumberCart {
        margin-left: 0;
        margin-top: 2em;
        min-height: 20rem;
    }

    .purchaseNumberSelectorFooter {
        padding: 0 1em;
    }

    .purchaseNumberSelector {
        max-height: 25rem;
    }

    .cartAmountContainer {
        padding-top: 0.5rem;
    }

    .cartButtonContainer{
        margin-bottom: 2rem;
    }
}
.pageHeader {
  font-size: 1.8rem;
  font-width: 600;
  color: var(--blue);
  margin-bottom: 1rem;
  margin-left: 1rem;
}

.profileDetailsContainer {
  padding: 2rem;
  border-radius: 30px;
  box-shadow: var(--semiTransparentBlackShadow);
  display: flex;
  background: var(--white);
  width: 90%;
  margin: auto;
  min-width: 0;
}

.highlightContainer {
  flex: .6;
  padding: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  justify-items: center;
  align-content: center;
}

.detailContainer {
  flex: 1;
  padding: 1rem 1rem 0;
}

.nameInitialContainer {
  background: var(--purplishPink);
  border: 5px solid white;
  border-radius: 50%;
  margin: 8% auto 2rem;
  color: var(--white);
  box-shadow: var(--semiTransparentBlackShadow);
  position: relative;
  width: 6rem;
  height: 6rem;
}

.nameInitialContainer h2 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin: 0;
  font-size: 4rem;
}

.userDetailContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 1rem;
  flex: 1;
  width: 100%;
  text-align: center;
}

.popUpName {
  font-size: 1rem;
  font-weight: 600;
  color: var(--blue);
}

.popUpId {
  color: var(--gray);
  font-size: 0.8rem;
  font-weight: 500;
}

.header {
  font-size: 1.5rem;
  color: var(--blue);
  font-weight: 600;
}

.detailListContainer {
  box-shadow: var(--semiBlackShadow);
  border-radius: 20px;
  margin-top: .5rem;
  max-height: 46vh;
  height: fit-content;
  overflow: auto;
  scrollbar-width: none;
}

.detailListContainer::-webkit-scrollbar {
  display: none;
}

.details {
  width: 100%;
  font-size: .8rem;
  color: var(--gray);
  overflow: auto;
}

.details tr {
  border-radius: 20px;
}

.details tr:nth-child(odd) {
  background: var(--titenWhite);
}

.details tr:nth-child(even) {
  background: var(--white);
}

.details tr td {
  padding: .6rem;
  width: 50%;
}

.details tr td:nth-child(odd) {
  font-weight: bold;
  border-radius: 20px 0 0 20px;
}

.details tr td:nth-child(even) {
  border-radius: 0 20px 20px 0;
}

@media only screen and (max-width: 720px) {
  .profileContainer {
    height: calc(100dvh - 12rem);
  }

  .pageHeader {
    text-align: center;
    margin-left: 0;
  }

  .profileDetailsContainer {
    flex-direction: column;
    padding: 1rem;
    width: 85%;
    margin-bottom: 2.5rem;
  }

  .highlightContainer {
    padding-top: 0;
  }

  .detailContainer {
    padding: 0.5rem;
  }

  .detailListContainer {
    max-height: none;
  }

  .highlightHeaderContainer {
    display: flex;
    gap: 0.5rem;
  }

  .nameInitialContainer {
    width: 4rem;
    height: 4rem;
  }

  .nameInitialContainer > h2 {
    font-size: 3rem;
  }

  .userDetailContainer {
    align-items: flex-start;
  }
}

@media only screen and (min-width: 720px) and (max-width: 1024px) {
  .pageHeader {
    padding-left: 2rem;
  }

  .profileDetailsContainer {
    padding: 1rem;
    width: 85%;
    margin-bottom: 6rem;
  }

  .highlightContainer {
    padding: 0 1rem;
  }

  .highlightHeaderContainer {
    display: flex;
    gap: 1rem;
  }

  .userDetailContainer {
    align-items: flex-start;
  }
}

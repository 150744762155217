.container {
    height: 88%;
}

.tableContainer {
    background: var(--white);
    border-radius: 22px;
    box-shadow: var(--semiTransparentDarkShadow);
    height: 100%;
}

.statusBadge {
    color: white;
    padding: 0.2rem 0.4rem;
    font-weight: bold;
    border-radius: 5px;
}

.success{
    background-color: #A7F3D0;
    color: #166534;
}

.pending{
    background-color: #FEE2B3;
    color: #B45309;
}

.failed {
    background-color: #FECACA;
    color: #9B1C31;
}

.transactionType{
    display: flex;
    gap: 0.3rem;
    justify-content: center;
    align-items: center;
}

.creditBadge {
    color: #16A34A;
}

.debitBadge {
    color: #DC2626;
}

.dateContainer{
    position: relative;
}

.infoIcon{
    position: absolute;
    top: 0;
    right: -3vw;
    cursor: pointer;
}

.infoIcon:hover {
    color: var(--blue);
}

.transactionInfoContainer{
    background: var(--white);
    padding: 1rem;
    border-radius: 10px;
}

.details {
    width: 100%;
    font-size: .8rem;
    color: var(--gray);
    overflow: auto;
    border-collapse: collapse;
    margin-top: 0.5rem;
}

.details tr td {
    padding: .6rem;
    width: 35%;
    border-bottom: 1px solid var(--lightGray);
    font-weight: bold;
}

.details tr .transactionBodyDetail {
    width: 65%;
    font-weight: normal;
}

.buttonContainer{
    display: flex;
    margin-top: 1rem;
    justify-content: center;
}

.position {
    position: relative;
}

.copyIcon{
    position: absolute;
    right: 1vw;
    top: 40%;
    cursor: pointer;
}

.copyIcon:hover {
    color: var(--blue);
}
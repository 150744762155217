.uploadButtonContainer {
    display: flex;
    margin-top: 2rem;
    justify-content: center;
    align-items: stretch;
    flex-direction: column;
    gap: 1rem;
    /*height: 15rem;*/
    margin-left: 3rem;
    margin-right: 3rem;
}

.galleryIcon,
.cameraIcon {
    background-color: var(--coldPurple);
    cursor: pointer;
    padding: 0.5rem 2rem;
    border-radius: 30px;
    box-shadow: 2px 3px 4px 2px #72727252;
    height: 1.5rem;
    width: 1.5rem;
}

.arrowBackIcon {
    z-index: 25;
    position: absolute;
    top: 10px;
    left: 10px;
    color: var(--coldPurple);
    cursor: pointer;
}

.errorMessage {
    color: red;
    font-size: 0.8rem;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 0.5rem;
}

.errorMessage > img {
    width: 1rem;
    height: 1rem;
    padding-right: 0.3rem;
}

.webcamContainer {
    text-align: center;
    padding: 0.7rem;
}

.cameraButtonContainer {
    display: flex;
    justify-content: center;
    gap: 1rem;
}

.uploadText{
    color: var(--coldPurple);
    text-align: center;
    font-weight: 600;
    font-size: 1.2rem;
}

.uploadButton{
    display:flex;
    justify-content: center;
    align-items: center;
    gap: 1.5rem;
    margin-bottom: 1rem;
}
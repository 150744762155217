@import '../../colors.css';

.dashBoardScreenContainer {
    height: 100%;
    overflow: auto;
    scrollbar-width: none;
}

.dashBoardScreenContainer::-webkit-scrollbar {
    display: none;
}

.parentStatContainer {
    display: flex;
    min-width: 0;
    padding-bottom: 1rem;
}

.header {
    font-size: 2.1rem;
    color: var(--blue);
    margin-bottom: 1rem;
    font-weight: 500;
}

.leftWidgetContainer {
    flex: 4;
}

.rightWidgetContainer {
    flex: 1;
}

.upperLeftContainer {
    display: flex;
}

.widgetCell {
    flex: 1;
    padding: 1.2rem;
}

.widget {
    border-radius: 2rem;
    width: 100%;
    height: 100%;
}

.square {
    aspect-ratio: 1/1;
}

.twoByOne {
    aspect-ratio: 2/1;
}

.fourByOne {
    aspect-ratio: 4/1;
}

.oneByTwo {
    aspect-ratio: 1/1.4;
}

.resizable {
    min-width: 0;
}

.lowerLeftContainer {
    display: flex;
    flex-direction: column;
}

.statContainer {
    flex: 4;
    display: flex;
}

.activityContainer {
    flex: 1;
}

.numberWidgetContainer {
    flex: 1;
}

.graphContainer {
    flex: 3;
    min-width: 0
}

.clickable {
    cursor: pointer
}

@media only screen and (min-width: 481px) and (max-width: 1024px) {
    .parentStatContainer {
        flex-direction: column;
        width: 97vw;
    }

    .hidden {
        display: none;
    }

    .widgetCell {
        padding: 0.5rem;
    }

    .square {
        aspect-ratio: 1/0.7;
    }

    .numberWidgetContainer{
        justify-content: center;
        flex-direction: row;
        display: flex;
    }

    .twoByOne {
        aspect-ratio: 0;
    }

    .rightWidgetContainer {
        display: flex;
        flex-direction: row;
        margin-bottom: 6rem;
    }

    .responsive {
        aspect-ratio: 0;
    }

    .header{
        padding-left: 1rem;
        font-size: 4vw;
        text-align: center;
    }
}

@media only screen and (max-width: 480px) {
    .header {
        text-align: center;
        width: 95vw;
        font-size: 5vw;
    }

    .parentStatContainer {
        flex-direction: column;
        width: 95vw;
        }

    .widgetCell {
        flex: 0;
        padding: 0.3rem;
    }

    .square {
        aspect-ratio: 1/0.7;
    }

    .upperLeftContainer {
        flex-direction: row;
        width: 100%;
    }

    .rightWidgetContainer {
        flex-direction: column;
    }

    .upperLeftContainer {
        flex-wrap: wrap;
        justify-content: center;
    }

    .numberWidgetContainer,
    .graphContainer,
    .responsive {
        justify-content: center;
        padding: 0.3rem 0;
        display: flex;
    }

    .responsive {
        height: fit-content;
    }

    .statContainer {
        padding: 0 0.5rem;
        justify-content: center;
        flex-direction: column;
    }

    .numberWidgetContainer {
        display: flex;
    }

    .hidden {
        display: none;
    }
}
.skyButton {
    border-radius: 30px;
    border: 0;
    cursor: pointer;
    align-items: center;
    height: max-content;
}

.enabled {
    pointer-events: auto;
    opacity: 1;
}

.disabled {
    pointer-events: none;
    opacity: 0.5;
}

.defaultBtnType {
    background: var(--blue);
    color: var(--white);
}

.errorBtnType {
    background: var(--red);
    color: var(--white);
}

.cancelBtnType {
    background: #d3d3d3;
    color: #000000;
}

.largeBtn {
    width: 100%;
    padding: 0.5em 1em;
}

.mediumBtn {
    width: 50%;
    padding: 0.5em 1em;
}

.smallBtn {
    width: fit-content;
    padding: 0.5em 1em;
}

.fitContentBtn {
    width: fit-content;
}

@media only screen and (max-width: 425px) {
    .skyButton{
        font-size: 0.7rem;
    }
}
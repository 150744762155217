.infoContainer {
    height: auto;
    max-height: 38vh;
    width: calc(100% - 6rem);
    margin-top: 2rem;
    padding: 3rem 3rem 0rem;
    background: var(--semiTransparentWhite);
    box-shadow: var(--semiTransparentLavender);
    border-radius: 1rem;
    overflow: auto;
    scrollbar-width: none;
}

.infoContainer::-webkit-scrollbar {
    display: none;
}

.bottomImage {
    width: 95%;
    margin-top: 2%;
    height: auto;
}

.circleImage {
    margin-top: -1rem;
    width: 10%;
}

.titleContainer {
    display: flex;
}

.titleText {
    font-family: 'Roboto', sans-serif;
    font-size: 1.5vw;
    color: var(--cyan);
}

.descriptionContainer {
    font-family: 'Roboto', sans-serif;
    font-size: 1.2vw;
    color: var(--gray);
    word-break: break-word;
}

@media only screen and (max-width: 480px) {
    .infoContainer {
        margin-top: 0.5rem;
        padding: 1rem 1rem 0;
        width: calc(100% - 2rem);
        max-height: 30vh;
    }

    .titleText {
        font-size: 3.5vw;
    }

    .circleImage {
        margin-top: 0;
    }

    .descriptionContainer {
        font-size: 2.7vw;
    }

    .bottomImage {
        width: 100%;
    }
}

@media only screen and (min-width: 480px) and (max-width: 1024px) {
    .infoContainer {
        margin-top: 0.5rem;
        padding: 1rem 1rem 0;
        width: calc(100% - 2rem);
        max-height: 32vh;
    }

    .titleText {
        font-size: 2.5vw;
    }

    .circleImage {
        margin-top: -0.5rem;
    }

    .descriptionContainer {
        font-size: 1.8vw;
    }

    .bottomImage {
        width: 100%;
    }
}
.checkoutContainer {
    display: flex;
    height: calc(100vh - 12rem);
    justify-content: center;
    align-items: center;
}

.checkoutWrapperContainer {
    height: 90%;
    width: 80%;
    background-color: white;
    border-radius: 20px;
    padding: 1rem;
    margin-top: 3rem;
    display: flex;
}

.imageContainer {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 40rem;
}

.checkoutImage {
    width: 100%;
    object-fit: contain;
    scale: 0.8;
}

.checkoutSummary {
    flex: 1;
    overflow-y: auto;
    scrollbar-width: none;
}

.checkout {
    padding: 0.5rem 1rem;
}

.header {
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid #e5e7eb;
}

.headerTitle {
    font-size: 1.5rem;
    font-weight: 500;
    color: var(--blue);
    font-family: "Roboto", sans-serif;
    margin: 0.5rem 0;
}

.section {
    padding-bottom: 1.25rem;
    border-bottom: 1px solid #e5e7eb;
    font-family: "Roboto", sans-serif;
}

.sectionTitle {
    font-size: 1.125rem;
    font-weight: 500;
    color: #111827;
    margin-bottom: 1rem;
}

.cartProduct,
.gatewayFee {
    display: flex;
    justify-content: space-between;
    color: var(--gray);
}

.cartProductText {
    color: var(--gray);
}

.grandTotal {
    font-weight: bold;
}

.summaryRow {
    display: flex;
    justify-content: space-between;
    margin: 0.5rem 0;
    font-family: "Roboto", sans-serif;
}

.summaryTotal {
    color: #111827;
    font-weight: 500;
}

.paymentButton {
    display: flex;
    justify-content: flex-end;
    margin-top: 1rem;
}

.block {
    display: block;
}

.none {
    display: none;
}

.cartContainer {
    box-shadow: 1px 1px 9px 1px rgba(0, 0, 0, 0.3);
    margin: 1rem;
    padding: 1rem 0.5rem 0;
    border-radius: 10px;
}

@media screen and (max-width: 1024px) {
    .checkoutContainer {
        align-items: flex-start;
    }

    .checkoutWrapperContainer {
        margin-top: 0;
    }
}

@media screen and (max-width: 768px) {
    .imageContainer {
        display: none;
    }
}
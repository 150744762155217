.registrationDialogContainer {
    font-family: Roboto, sans-serif;
    display: flex;
    flex-direction: column;
    padding: 0 2rem 1rem;
    background-color: var(--white);
    border-radius: 10px;
}

.registrationDetailsHeader{
    color: var(--blue);
    font-weight: 500;
    text-align: center;
}
.registrationTableContainer {
    display: flex;
    flex-direction: column;
    gap: 1.2rem;
}

.registrationGrid {
    width: 100%;
}

.registrationHeader {
    display: flex;
    font-weight: bold;
    color: var(--gray);
    border-bottom: 2px solid rgba(0, 0, 0, 0.2);
}

.registrationDetails {
    max-height: 20rem;
    overflow-y: auto;
    padding-right: 5px;
}

.registrationRow {
    display: flex;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.headerSerialNo,
.headerClientAgent,
.headerRegistrationIp,
.serialNumberCol,
.clientAgentCol,
.registrationIpCol {
    flex: 1;
    padding: 0.5rem 1rem;
    text-align: left;
}

.serialNumberCol,
.clientAgentCol,
.registrationIpCol {
    color: var(--blue);
    font-weight: 500;
    font-size: 0.9rem;
}

.noRegistrationMessage {
    color: var(--gray);
    text-align: center;
}

.buttonContainer {
    display: flex;
    justify-content: center;
    margin-top: 1.5rem;
}

@media (max-width: 600px) {
    .serialNumberCol,
    .clientAgentCol,
    .registrationIpCol {
        font-size: 0.8rem;
    }

    .noRegistrationMessage {
        font-size: 0.9rem;
    }

    .headerSerialNo,
    .headerClientAgent,
    .headerRegistrationIp,
    .serialNumberCol,
    .clientAgentCol,
    .registrationIpCol {
        padding: 0.4rem 0.8rem;
    }
}

@media (max-width: 480px) {
    .registrationDetailsHeader{
       font-size: 1rem
    }
    .serialNumberCol,
    .clientAgentCol,
    .registrationIpCol {
        font-size: 0.5rem;
    }

    .noRegistrationMessage {
        font-size: 0.9rem;
    }

    .headerSerialNo,
    .headerClientAgent,
    .headerRegistrationIp,
    .serialNumberCol,
    .clientAgentCol,
    .registrationIpCol {
        padding: 0.4rem 0.8rem;
        font-size: .5rem;
    }

    .registrationDialogContainer {
        padding: 0 1rem 1rem;
    }

    .buttonContainer {
        margin-top: 1rem;
    }
}
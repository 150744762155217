.header {
    display: flex;
    justify-content: flex-end;
    padding-right: 2vw;
}

.profileIconContainer {
    display: flex;
    align-items: center;
    justify-content: right;
    flex: .15;
    z-index: 5;
}

.greetContainer {
    flex: 2;
    text-align: right;
}

.greetText {
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-size: 1rem;
    font-weight: 450;
    color: var(--blue);
}

.profileCircle {
    font-family: 'Roboto', sans-serif;
    background: var(--purplishPink);
    color: var(--white);
    width: 3vw;
    cursor: pointer;
    float: left;
    border-radius: 50%;
    height: 3vw;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.5rem;
    font-weight: 600;
    padding: .1rem;
}

.arrowIcon {
    width: 1vw;
    cursor: pointer;
    margin-left: .2vw;
}

.rotate {
    transform: translate(0%, -50%) rotate(180deg);
}

.message {
    height: 2vh;
    position: relative;
    left: 17.5vw;
    top: -4.6vh;
}

.profile_span {
    position: relative;
    top: -2.2vh;
    left: -4vw;
    font-size: 0.6rem;
    font-weight: 600;
}

.show_message {
    width: 15%;
    position: relative;
    top: -3vw;
    left: 24.8vw;
    cursor: pointer;
}

.show_message_arrow_icon {
    width: 8%;
    position: relative;
    top: -3vw;
    left: 25.5vw;
    cursor: pointer;
}

.popup {
    width: 13.5%;
    height: 39vh;
    position: relative;
    bottom: 81vh;
    left: 81vw;
    border-radius: 30px;
    background: var(--blue);
    box-shadow: var(--dullLavenderShadow);
}

.popup_second_card {
    display: flex;
    flex-direction: column;
    justify-items: center;
    align-items: center;
    gap: 5%;
    border-radius: 28px;
    height: 34vh;
    background: var(--titenWhite);
    box-shadow: var(--blackShadow);
}

.active_symbol {
    position: relative;
    top: 5.4vh;
    left: 1.5vw;
    background: var(--green);
    border-radius: 50%;
    width: 5%;
    height: 1.5vh;
}

.pop_up_name_container {
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--purplishPink);
    border: 5px solid var(--white);
    border-radius: 50%;
    width: 26%;
    height: 7vh;
    margin-top: 8%;
    font-size: 2rem;
    color: var(--white);
    box-shadow: var(--semiTransparentBlackShadow);
}

.pop_up_detail {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.popUpName {
    font-size: 1rem;
    font-weight: 600;
    color: var(--blue);
}

.popUpId {
    color: var(--gray);
    font-size: 0.8rem;
    font-weight: 500;
}

.popUpAddress {
    font-size: 0.8rem;
    font-weight: 600;
    color: var(--blue);
}

.pop_up_logout {
    position: absolute;
    top: 90%;
    left: 29%;
    width: 6vw;
    height: 3vh;
    border-radius: 40px;
    border: 0;
    background: transparent;
    color: var(--white);
    cursor: pointer;
    padding: 1%;
    font-size: 0.68rem;
    font-weight: 500;
    text-align: center;
}

@media only screen and (max-width: 1024px) {
    .header {
        flex-direction: row-reverse;
        padding-left: 0.5rem;
        position: fixed;
        top: 1rem;
        width: 100vw;
        background:#F1EFFF ;
        z-index: 10;
        height: 3rem;
        padding-top: 1rem;
    }

    .greetContainer {
        text-align: left;
        margin-top: 0;
        padding-left: 0.5rem;
        display: flex;
        align-items: center;
    }

    .profileCircle {
        width: 1.8rem;
        height: 1.8rem;
        border: 2px solid var(--white);
        box-shadow: 2px 2px 3px 1px #c8c6ff
    }

    .arrowIcon {
        display: none;
    }
}

@media only screen and (max-width: 480px) {
    .greetText {
        font-size: 0.8rem;
    }
}

@media only screen and (max-width: 320px) {
    .greetText {
        font-size: 0.6rem;
        display: flex;
        align-items: center;
    }

    .header {
        height: 2rem;
    }
}
.outerContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    overflow: auto;
    scrollbar-width: none;
}

.rechargeContainer {
    display: flex;
    align-items: center;
    flex-direction: column;
    border-radius: 2rem;
    background-color: rgb(253, 255, 255);
    padding-left: 5rem;
    padding-right: 5rem;
    padding-bottom: 2rem;
}

.topBar {
    background-color: var(--blue);
    width: calc(70% + 10rem);
    height: 8vh;
    border-bottom-left-radius: 100px;
    border-bottom-right-radius: 100px;
    box-shadow: var(--transparentBlackShadow);
    text-align: center;
}

.logoImage {
    width: 8vh;
    top: 50%;
    left: 45%;
    border: 2px solid var(--white);
    border-radius: 50%;
    margin-top: 4vh;
}

.rechargeHeading {
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 500;
    letter-spacing: 0.009rem;
    color: var(--blue);
    margin-top: 2rem;
    font-size: 1.2rem;
}

.rechargePara {
    position: relative;
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 0.8rem;
    line-height: 1rem;
    text-align: center;
    color: var(--gray);
}

.formFieldContainer {
    width: 90%;
    color: var(--warmBlue);
    font-size: 0.7rem;
    display: flex;
    flex-direction: column;
    margin-top: 1rem;
    align-items: center;
}

.row {
    flex: 1;
    display: flex;
    margin-bottom: 1rem;
    width: 100%;
}

.label {
    font-weight: bold;
    font-size: 1rem;
}

.usd, .amountFieldContainer {
    font-weight: bold;
    font-size: 1.2rem;
    margin-left: 1rem;
}

.amountContainer {
    position: relative;
}

.amountField {
    box-shadow: var(--transparentBlackShadow);
    border-radius: 16px;
    border: 1px solid #947AFF;
    font-size: 1rem;
    padding-top: .2rem;
    padding-bottom: .2rem;
    width: 100%;
}

.amountField::-webkit-outer-spin-button,
.amountField::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.feeTotalContainer {
    width: 100%;
    background-color: var(--titenWhite);
    border-radius: 1rem;
    padding: 0.75rem 1.25rem;
    margin: 0.5rem 0;
}

.feeRow {
    display: flex;
    align-items: center;
    padding: 0.5rem 0;
    color: var(--blue);
    font-size: 0.9rem;
}

.totalRow {
    display: flex;
    align-items: center;
    padding-top: 0.5rem;
    color: var(--blue);
    font-size: 1.1rem;
    font-weight: 600;
    border-top: 1px solid rgba(123, 97, 255, 0.2);
}

.feeLabel, .totalLabel {
    flex: 1;
}

.feeAmount, .totalAmount {
    color: #6D28D9;
}

@media only screen and (max-width: 1024px) {
    .outerContainer {
        margin-bottom: 5rem;
    }
}

@media only screen and (max-width: 480px) {
    .outerContainer {
        height: 80dvh;
        width: 100%;
        margin-bottom: 3rem;
    }

    .rechargeContainer {
        padding-left: 0.5rem;
        padding-right: 0.5rem;
    }

    .topBar {
        width: calc(60% + 5rem);
    }

    .rechargeHeading > h2 {
        font-size: 1.5rem;
        text-align: center;
    }

    .amountField {
        width: 100%;
    }

    .feeTotalContainer {
        padding: 0.5rem 1rem;
    }

    .feeRow,
    .totalRow {
        font-size: 0.9rem;
    }
}
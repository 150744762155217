.authMobileBaseContainer {
    overflow-x: hidden;
    overflow-y: auto;
}

.welcomeSection {
    width: 100%;
    height: 100vh;
    background-color: white;
}

.welcomeContent {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.welcomeTitle {
    font-family: 'Inspiration', cursive;
    font-style: normal;
    font-size: 6em;
    text-align: center;
    color: var(--blue);
    padding-top: 1.5rem;
    overflow: hidden;
}

@font-face {
    font-family: 'Inspiration';
    src: url('../../../public/Inspiration-Regular.ttf');
    font-weight: normal;
    font-style: normal;
}

.welcomeLetter {
    display: inline-block;
    opacity: 0;
    transform: translateX(-100%);
    animation: revealLetter 0.5s cubic-bezier(0.25, 0.1, 0.25, 1) forwards;
}

@keyframes revealLetter {
    to {
        opacity: 1;
        transform: translateX(0);
    }
}

.welcomeLetter:nth-child(1) {
    animation-delay: 0.1s;
}

.welcomeLetter:nth-child(2) {
    animation-delay: 0.2s;
}

.welcomeLetter:nth-child(3) {
    animation-delay: 0.3s;
}

.welcomeLetter:nth-child(4) {
    animation-delay: 0.4s;
}

.welcomeLetter:nth-child(5) {
    animation-delay: 0.5s;
}

.welcomeLetter:nth-child(6) {
    animation-delay: 0.6s;
}

.welcomeLetter:nth-child(7) {
    animation-delay: 0.7s;
}

.welcomeImage {
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    scale: 0.8;
    height: 65%;
    border-radius: 35%;
    margin-top: -2rem;
}

.registerImage {
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    height: 65%;
    display: block;
    margin: 0 auto;
}

.formSection {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2rem 1rem;
    border-radius: 15px 15px 0 0;
    margin-top: -30px;
}

@media only screen and (max-width: 768px) {
    .registerImage {
        height: 60%;
    }

    .welcomeSection {
        height: 650px;
    }
}

@media only screen and (max-width: 650px) {
    .welcomeSection {
        height: 600px;
        background-image: url('../../images/mobile-screen-background.png');
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        background-color: transparent;
    }

    .welcomeImage {
        height: 50%;
    }

    .registerImage {
        height: 50%;
    }

    .formSection {
        margin-top: -80px;
    }

    .welcomeTitle {
        font-size: 4em;
    }
}

@media only screen and (max-width: 450px) {
    .welcomeSection {
        height: 500px;
    }

    .registerImage {
        height: 45%;
    }

    .formSection {
        padding: 2rem 0;
    }
}


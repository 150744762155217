.menubar {
    margin-left: 5rem;
    display: flex;
    height: 80vh;
}

.collapsedSidebar {
    background: var(--blue);
    border-radius: 23px;
    width: 3.2rem;
    box-shadow: var(--bilobaFlowerShadow);
    padding-top: 10px;
    z-index: 2;
    display: flex;
    flex-direction: column;
}

.sidebarExpandable {
    color: var(--gray);
    font-size: 14px;
    padding-top: 10px;
    transition: 0.5s;
    display: flex;
    flex-direction: column;
    opacity: 1;
}

.sidebarExpandableHidden {
    margin-left: 0rem;
    border-radius: 15px;
    padding-left: 0rem;
    padding-right: 0rem;
    width: 0;
    transition: 0.5s;
    opacity: 0;
}

.sideBarExpandableOpen {
    margin-left: -3rem;
    border-radius: 15px;
    background: var(--semiTransparentWhite);
    box-shadow: var(--transparentBlackShadow);
    padding-left: 4rem;
    padding-right: 1rem;
    width: 8.5rem;
}

.logoContainer {
    margin-top: 1vh;
    text-align: center;
    flex: 1;
    cursor: pointer;
}

.searchBoxContainer {
    flex: 1;
    display: flex;
    align-items: center;
    margin-top: 1.9vh;
}

.logoImage {
    width: 60%;
    margin: auto;
    border: 4px solid var(--white);
    border-radius: 50%;
    filter: drop-shadow(var(--semiTransparentBlackShadow));
}

.toggleIcon {
    position: absolute;
    margin-top: 2.8rem;
    width: 19px;
    height: 19px;
    margin-left: 2.5rem;
    cursor: pointer;
    filter: drop-shadow(var(--semiTransparentBlackShadow));
}

.toggleIconInwards {
    margin-left: 13rem;
    transition: margin-left 0.5s;
    filter: drop-shadow(var(--semiTransparentBlackShadow))
}

.toggleIconOutWards {
    margin-left: 2.5rem;
    transition: margin-left 0.5s;
}

.topMenuIconContainer {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    flex: 10;
}

.topMenuItemContainer {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    cursor: pointer;
    padding-left: 1rem;
    flex: 10;
}

.bottomMenuIconContainer {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;
    flex: 2;
}

.bottomMenuItemContainer {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    cursor: pointer;
    padding-left: 1rem;
    flex: 2;
}

.iconContainer {
    margin-top: 3vh;
    cursor: pointer;
    width: 100%;
    display: flex;
    justify-content: center;
    align-content: center;
    padding-top: .1rem;
    padding-bottom: .1rem;
}

.iconContainerHoverEffect {
    animation: iconContainerMouseEffect .5s;
    animation-fill-mode: forwards;
}

.iconContainerUnHoverEffect {
    animation: iconContainerMouseEffectAlt .5s;
    animation-fill-mode: forwards;
}

@keyframes iconContainerMouseEffect {
    from {box-shadow: var(--transparentWhite);}
    to {box-shadow: var(--whitishShadow);}
}

@keyframes iconContainerMouseEffectAlt {
    from {box-shadow: var(--whitishShadow);}
    to {box-shadow: var(--transparentWhite);}
}

.sidebar-item-dashboard {
    text-decoration: none;
    color: var(--white);
}

.setting-image-dashboard {
    width: 1.4rem;
    height: 1.4em;
    margin-top: 14.8rem;
    margin-left: 0.9em;
}

.menuIcon {
    width: 1.5rem;
}

.sidebar-menu {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.searchIcon {
    width: .7rem;
    margin-right: 1rem;
    flex: .1;
}

.searchBar {
    height: 3vh;
    outline: none;
    background: var(--paleLavender);
    box-shadow: var(--darkShadeShadow);
    border-radius: 16px;
    padding-left: 15px;
    display: flex;
    align-items: center;
}

.searchInput {
    font-family: 'Public Sans', sans-serif;
    font-style: normal;
    color: var(--mediumDark);
    border: 0;
    background: transparent;
    margin-left: 7px;
    margin-bottom: 3px;
    flex: 1;
}

.searchInputExpanded {
    width: 94%;
}

.searchInputClosed {
    width: 0%;
}

button[type="submit"]:hover {
    opacity: 1;
}

input:focus {
    outline: none;
}

.sidebar-item {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}


.menuTextHoverEffect::after {
    box-shadow: var(--darkBlackShadow);
    position: absolute;
    content: "";
    left: -3rem;
    bottom: 0;
    width: calc(100% + 4rem);
    height: 100%;
    background: transparent;
    animation: menuTextMouseEffect .5s;
    animation-fill-mode: forwards;
}

.menuTextUnHoverEffect::after {
    box-shadow: var(--darkBlackShadow);
    position: absolute;
    content: "";
    left: -3rem;
    bottom: 0;
    width: calc(100% + 4rem);
    height: 100%;
    background: transparent;
    animation: menuTextMouseEffectAlt .5s;
    animation-fill-mode: forwards;
}

@keyframes menuTextMouseEffect {
    from {box-shadow: var(--transparentShadow);}
    to {box-shadow: var(--darkBlackShadow);}
}

@keyframes menuTextMouseEffectAlt {
    from {box-shadow: var(--darkBlackShadow);}
    to {box-shadow: var(--transparentShadow);}
}

.menuTextContainer {
    position: relative;
    margin-top: 3.4vh;
    cursor: pointer;
    font-size: 1rem;
    line-height: 1.4rem;
    justify-content: flex-start;
}

@media screen and (max-width: 1024px) {
    .menubar {
        position: fixed;
        z-index: 5;
        bottom: 0;
        height: 4rem;
        margin-left: 0;
    }

    .collapsedSidebar {
        background-color: transparent;
        box-shadow: none;
        flex-direction: row;
        height: 4rem;
        width: 100vw;
    }

    .topMenuIconContainer {
        flex-direction: row;
        background: var(--blue);
        height: 3.5rem;
    }

    .sidebarExpandable,
    .bottomMenuIconContainer,
    .logoContainer,
    .toggleIcon {
        display: none;
    }

    .bottomMenuIconContainer {
        flex: 0;
    }

    .iconContainer {
        margin-top: 0;
    }

    .selectedIcon {
        background-color: #3811DA;
        border-radius: 10px;
        padding: 0.3rem;
        box-shadow: 2px 2px 1px 1px #838fd2;
    }

    .iconContainerHoverEffect,
    .iconContainerUnHoverEffect {
        animation: none;
    }
}

@media screen and (max-height: 768px) {
    .menuTextContainer {
        line-height: 1.55rem;
    }
}

.otpContainer{
    background: white;
    min-height: 6rem;
    border-radius: 8px;
    padding-top: .5rem;
    padding-bottom: .5rem;
    text-align: center;
}

.infoText {
    color: var(--darkSilver)
}

.otpInputsContainer {
    justify-content: center;
    margin-bottom: 1rem;
}

.otpInputBox {
    border: 1px solid var(--purple);
    border-radius: 5px;
    padding: .6rem;
}

.otpInputBoxOnError {
    border: 1px solid var(--red);
}

.separator {
    width: .6rem;
}

.errorText {
    color: var(--darkRed);
}

.timerText {
    color: var(--gray);
    font-family: Roboto,serif;
    font-size: .8rem;
}
.resendText{
    color: var(--blue);
    font-family: Roboto, serif;
    cursor: pointer;
    font-size: .8rem;
}
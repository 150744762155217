.calendarContainer {
    background: var(--white);
    border-radius: 20px;
    box-shadow: var(--transparentBlackShadow);
    overflow: hidden;
}

.calendarContainer > div:first-child > div:first-child {
    background-color: var(--blue);
    border-radius: 20px;
    box-shadow: var(--transparentBlackShadow);
}

.calendarContainer > div:first-child > div:first-child span {
    border-radius: 20px;
    box-shadow: var(--transparentBlackShadow);
}

.rdrMonth {
    background-color: var(--red);
}

.shifted {
    transform: translate(2rem, 6rem);
}

.headingContainer {
    margin-top: .2rem;
    margin-bottom: .2rem;
}

.calenderHeading {
    text-align: center;
    color: var(--blue);
    font-family: 'Roboto', sans-serif;
    font-weight: bold;
}

.dateContainer {
    display: flex;
    justify-content: space-around;
    align-items: center;
    background: var(--blue);
    width: 110%;
    margin-left: -5%;
    padding: 0.9%;
    border-radius: 18px;
    color: var(--white);
    box-shadow: var(--coldPurpleShadow);
    cursor: pointer;
    height: 2rem;

    flex: 1;
}

.dateRangePickerContainer {
    margin-top: -15rem;
    margin-left: -1rem;
    padding-right: 2rem;
}

.hidden {
    display: none;
}

.cdr_date_picker_open {
    box-shadow: var(--brightLavenderShadow);
}

.dateContainer div {
    font-weight: 500;
    flex: 1;
    text-align: center;
    justify-content: center;
    justify-content: center;
    justify-items: center;
}


.cdr_container_expanded {
    width: 20%;
    height: 15vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0.5%;
    margin-left: 27%;
    margin-top: -28%;
    background: var(--white);
    border-radius: 18px;
}

@media screen and (max-width: 400px) {
.calendarContainer > div:first-child {
    font-size: 10px;
}

}
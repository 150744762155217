.migrateUserFormUpdateContainer {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    background-color: var(--white);
    border-radius: 1rem 1rem 0rem 0rem;
}

.dialogHeader {
    color: var(--blue);
    font-family: 'Roboto', sans-serif;
    margin: 0.5rem;
}

.migrateUserBoxDetail {
    color: var(--blue);
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
    font-size: 1rem;
    margin: 0.5rem;
}

.migrateUserEmailContainer,
.migrateUserConfirmEmailContainer {
    display: flex;
    flex-direction: column;
    gap: 0.2rem;
    align-self: stretch;
    padding: 0rem 3rem 0.3rem 3rem;
}

.migrateUserPasswordBoxContainer {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    padding: 0rem 3rem;
    gap: 0.7rem;
}

.migrateUserPasswordContainer {
    display: flex;
    justify-content: center;
    flex-direction: column;
    flex: 1;
}

.inputField {
    align-self: stretch;
    padding: 0.5rem;
    border-radius: 2rem;
    border: 1px solid var(--blue);
}

.errorInputField {
    outline: var(--red);
    border: 1px solid var(--red);
}

.infoText {
    margin-top: 0.2rem;
    font-size: 0.7rem;
    padding-left: 0.1rem;
    font-weight: 400;
    font-family: "Roboto", sans-serif;
    color: var(--gray);
}

.migrateUpdateButton,
.thankYouOkButton {
    margin-bottom: 1.5rem;
}

.migrateDotLoader {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.5rem 2rem;
    margin: 0.5rem 0.5rem 1.5rem;
}

.errorContainer {
    display: flex;
    align-content: center;
    justify-content: left;
    font-size: 0.7rem;
    color: var(--red);
    font-weight: 500;
    height: 1.5rem;
    margin-top: .5rem;
}

.errorIcon {
    width: .8rem;
    margin-right: 3px;
    height: .8rem;
}

.migrateUserFormThankYouContainer {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    background-color: var(--white);
    padding-top: 1rem;
}

.thankYouHeader {
    color: var(--blue);
    font-size: 1.5rem;
    font-weight: bold;
    margin: 0.5rem;
}

.thankYouText {
    padding: 1rem 2rem;
    font-family: "Roboto", sans-serif;
    font-weight: 300;
    font-size: 1rem;
    color: var(--semiTransparentBlackShadow);
    text-align: center;
    margin: 0;
}

.welcomeIcon {
    height: 8vh;
    padding-top: 0.3rem;
}

@media only screen and (max-width: 480px) {
    .migrateUserPasswordBoxContainer {
        flex-direction: column;
    }

    .padding {
        padding: 0 1rem;
    }

    .thankYouOkButton{
        padding-top: 0.5rem;
    }
}


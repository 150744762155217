.container {
    display: flex;
    height: 100%;
}

.welcomeContainer {
    flex: 2.5;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 98%;
}

.formContainer {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.formOuterContainer {
    display: flex;
    align-items: center;
}

.formInnerContainer {
    display: flex;
    align-items: center;
}

.imageContainer {
    flex: 2;
    overflow: hidden;
    width: 100%;
    display: flex;
    flex-direction: column;
}

.welcomeImage {
    border-radius: 47%;
    height: 90%;
    max-width: 100%;
}

.welcomeHeading {
    font-family: 'Inspiration';
    font-style: normal;
    font-size: 6rem;
    text-align: center;
    color: var(--blue);
    flex: 1;
    display: flex;
    align-items: flex-end;
}

@font-face {
    font-family: 'Inspiration';
    src: url('../../../../public/Inspiration-Regular.ttf');
    font-weight: normal;
    font-style: normal;
}

@media only screen and (max-width: 768px) {
    .resetContainer > div:nth-child(2) {
        margin-top: 4rem;
    }
}